<template>
  <div class="nutritionists"
       :style="{
        backgroundImage:
        'url(' + require('@/assets/images/Nutris.jpg') + ')'
    }"
  >
    <div class="nutritionists-content">
      <h3>SEGUIMIENTO</h3>
      <p class="title">NUTRICIONISTAS DEPORTIVOS</p>
      <p class="text">Elegiremos al mejor nutricionista deportivo
        para tu perfil y tu objetivo.</p>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.nutritionists
  height: 806px
  @apply bg-cover bg-bottom sm:bg-center
  .nutritionists-content
    @apply flex justify-center items-center content-center flex-col
    color: #171822
    font-family: 'Montserrat'
    font-style: normal
    padding: 12px
    h3
      top: -4.69
      font-weight: 500
      font-size: 12px
      line-height: 20px
      margin-top: 3em

    .title
      @apply md:min-w-full
      font-weight: 700
      font-size: 24px
      line-height: 32px
      max-width: 16em
      margin-top: 5px
    .text
      font-weight: 400
      font-size: 16px
      line-height: 25px
      max-width: 24em
      margin-top: 10px
  .nutritionists-extra-services
    display: flex
    justify-content: center
    align-items: flex-end

    div
      background-color: #FFFFFF
      border-radius: 16px
      margin-top: 28em
      width: 322px
      height: 98px
      display: flex
      flex-direction: row

      div
        display: flex
        flex-direction: column
        justify-content: center
        align-content: center
        align-items: center
        margin-top: 0
        img
          height: 38px
          width: 38px

    img
      margin: 7px 0

    p
      margin: 7px 0 0 0.5em
      text-align: left
      font-weight: 400
      font-size: 14px
      line-height: 18px

    p.videocall
      font-weight: 500
      font-size: 12px
      line-height: 20px

    p.other-text
      font-weight: 400
      font-size: 14px
      line-height: 18px
      max-width: 11em
</style>
