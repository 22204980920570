
import { defineComponent, reactive } from 'vue';
import store from '@/store';
import TimeOutStep1 from '@/components/molecules/TimeOutStep1.vue';
import TimeOutStep2 from '@/components/molecules/TimeOutStep2.vue';
import TimeOutStep3 from '@/components/molecules/TimeOutStep3.vue';
import Subscription from '@/domain/models/Subscription';
import useAmplitude from '@/composables/useAmplitude';

export default defineComponent({
  components: {
    TimeOutStep3,
    TimeOutStep2,
    TimeOutStep1,
  },
  props: {
    currentSubscription: {
      type: Subscription,
    },
  },
  emits: ['timeoutClose', 'cancel', 'timeOutPlanPurchase'],
  setup(props, { emit }) {
    const timeOut = reactive({
      Step1: true,
      Step2: false,
      Step3: false,
    });

    const { planTimeOut } = store.getters;
    const elementsIncluded = planTimeOut[0].subscriptions[0].elementsIncludedFront;
    const elementsNotIncluded = planTimeOut[0].subscriptions[0].elementsNotIncludedFront;
    const { elementsFeatured } = planTimeOut[0].subscriptions[0];

    function updateProfile() {
      timeOut.Step1 = false;
      timeOut.Step2 = false;
      timeOut.Step3 = false;
      window.location.href = '/profile';
    }

    function timeOutPlanPurchase() {
      timeOut.Step1 = false;
      emit('timeOutPlanPurchase', planTimeOut[0].subscriptions[0].paymentProductId);
      timeOut.Step2 = true;
      useAmplitude()?.logEvent('Timeout_plan_selected');
      (window as any)?.dataLayer.push({
        event: 'Timeout_plan_selected',
      });
    }

    function activateStep3() {
      timeOut.Step1 = false;
      timeOut.Step2 = false;
      timeOut.Step3 = true;
      useAmplitude()?.logEvent('Timeout_plan_info');
      (window as any)?.dataLayer.push({
        event: 'Timeout_plan_info',
      });
    }

    function desactivateStep3() {
      timeOut.Step3 = false;
      timeOut.Step1 = true;
    }

    function exitSteps() {
      timeOut.Step1 = false;
      timeOut.Step2 = false;
      timeOut.Step3 = false;
      emit('timeoutClose');
    }

    function cancel() {
      emit('cancel');
      useAmplitude()?.logEvent('Timeout_unsubscribe_selected');
      (window as any)?.dataLayer.push({
        event: 'Timeout_unsubscribe_selected',
      });
    }

    return {
      timeOut,
      planTimeOut,
      elementsIncluded,
      elementsNotIncluded,
      elementsFeatured,

      updateProfile,
      timeOutPlanPurchase,
      activateStep3,
      desactivateStep3,
      exitSteps,
      cancel,
    };
  },
});
