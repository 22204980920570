<template>
  <div class="appScreens">
    <div class="unique-as-you-header">
      <p class="section">APP</p>
      <p class="section-title">TAN ÚNICA COMO TÚ</p>
    </div>
    <div class="unique-as-you-content texts">
      <swiper :spaceBetween="50" :mousewheel="true"
              :pagination="{
                el: '.swiper-pagination',
                clickable: true,
              }"
              class="pagination-slider" ref="mySwiper"
              options="swiperOptions" style="max-width: 328px">
        <div class="swiper-pagination"></div>
        <swiper-slide class="swiper-slide">
          <UniqueAsYouMobileCard
              image="Plani.png"
              name="MENÚ SEMANAL COMPLETO"
              description="Planificación de todas las comidas
                de una semana para seguir durante un mes"
          />
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <UniqueAsYouMobileCard
              image="Cambios-de-recetas.png"
              name="CAMBIO INTELIGENTE DE RECETA"
              description="Elige otras alternativas sin alterar ni
                  una caloría tu arquitectura nutricional"
          />
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <UniqueAsYouMobileCard
              image="Entrenamientos.png"
              name="REAJUSTES AUTOMÁTICOS"
              description="Si tus entrenamientos cambian,
                  tu nutrición se reajusta automáticamente."
          />
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <UniqueAsYouMobileCard
              image="Chat.png"
              name="CHAT CON TU NUTRICIONISTA"
              description="Escribe a tu nutricionista cuando
                tengas dudas o necesites algún cambio puntual."
          />
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <UniqueAsYouMobileCard
              image="Graficos.png"
              name="GRÁFICOS DE EVOLUCIÓN"
              description="La nutrición ya no es invisible.
                Comparte tu mejora con tus amigos y enemigos."
          />
        </swiper-slide>
      </swiper>
      <div class="gp-apl-reviews">
        <div class="apple">
          <img :src="require('@/assets/images/android.png')" alt="android_icon">
          <p>250 valoraciones</p>
          <p>4,8</p>
          <div class="stars">
            <img :src="require('@/assets/images/fav_star.png')" alt="fav_star">
            <img :src="require('@/assets/images/fav_star.png')" alt="fav_star">
            <img :src="require('@/assets/images/fav_star.png')" alt="fav_star">
            <img :src="require('@/assets/images/fav_star.png')" alt="fav_star">
            <img :src="require('@/assets/images/fav_half_star.png')"
                 alt="fav_half_star">
          </div>
        </div>
        <div class="google">
          <img :src="require('@/assets/images/apple.svg')" alt="apple-icon">
          <p>95 valoraciones</p>
          <p>4,6</p>
          <div class="stars">
            <img :src="require('@/assets/images/fav_star.png')" alt="fav_star">
            <img :src="require('@/assets/images/fav_star.png')" alt="fav_star">
            <img :src="require('@/assets/images/fav_star.png')" alt="fav_star">
            <img :src="require('@/assets/images/fav_star.png')" alt="fav_star">
            <img :src="require('@/assets/images/fav_half_star.png')"
                 alt="fav_half_star">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Mousewheel, Pagination } from 'swiper';

// eslint-disable-next-line import/extensions
import 'swiper/scss';
// eslint-disable-next-line import/extensions
import 'swiper/scss/navigation';
// eslint-disable-next-line import/extensions
import 'swiper/scss/pagination';
import UniqueAsYouMobileCard from '@/components/molecules/UniqueAsYouMobileCard.vue';

export default {
  components: {
    UniqueAsYouMobileCard,
    // eslint-disable-next-line vue/no-unused-components
    Swiper,
    // eslint-disable-next-line vue/no-unused-components
    SwiperSlide,
  },
  setup() {
    const resolution = document.documentElement.clientWidth;

    SwiperCore.use([Mousewheel, Pagination]);

    return {
      resolution,
    };
  },
};
</script>

<style lang="sass" scoped>
.appScreens
  background-color: #000000
  height: 906px
  font-family: 'Montserrat'
  font-style: normal
  @apply bg-cover
  .unique-as-you-header
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    height: 100px
    p
      color: #FFFFFF

    p.section
      font-weight: 500
      font-size: 12px
      line-height: 20px

    p.section-title
      font-weight: 700
      font-size: 24px
      line-height: 32px

  .unique-as-you-content
    display: flex
    justify-content: center
    align-items: center
    margin-top: 3em
    margin-left: 15em
    flex-direction: column
    .swiper
      width: 100%
      height: 100%
      .swiper-pagination-bullets
        background: transparent
        display: flex
        flex-direction: row
        justify-content: center
        align-content: center
        height: 112px
        width: 100%
  .texts
    text-align: left
    margin-left: 2em
    margin-bottom: 3em

    div:hover
      p
        color: #FFFFFF

      h3
        color: #4CD2DB

    p
      max-width: 27em
      margin-bottom: 1em
      color: #FFFFFF

    h3
      color: #4CD2DB

    .gp-apl-reviews
      @apply flex flex-row justify-center
      margin-left: -1em
      margin-top: 5em
      div
        @apply flex flex-col justify-center items-center content-center
        margin: -1.5em 1em
        .stars
          @apply flex flex-row
        p:first-of-type
          @apply font-medium leading-5
          font-size: 8px

        p:nth-child(3)
          @apply font-bold text-5xl leading-5
</style>
