import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78297a95"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click'))),
    disabled: _ctx.disabled
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 8, _hoisted_1))
}