
import {
  computed,
  defineComponent,
  reactive,
} from 'vue';
import Button from '@/components/atoms/button.vue';
import {
  validateEmpty,
  validatePassword,
  validateEmail,
  validateDNI,
} from '@/domain/services/validationHelper';
import LoginUserForm from '@/domain/models/LoginUserForm';
import EmailInput from '../molecules/EmailInput.vue';
import PasswordInput from '../molecules/PasswordInput.vue';

export default defineComponent({
  components: {
    Button,
    EmailInput,
    PasswordInput,
  },
  emits: ['click', 'forgotEmail', 'forgotPassword'],
  props: {
    error: String,
  },
  setup(_, { emit }) {
    const form = reactive({
      email: '',
      password: '',
    });

    const formValidated = computed(() => {
      if (validateEmpty(form.password) || validateEmpty(form.email)) {
        return false;
      }

      if (!validateEmail(form.email) && !validateDNI(form.email)) {
        return false;
      }

      if (!validatePassword(form.password)) {
        return false;
      }

      return true;
    });

    const throwEvent = () => {
      if (!formValidated.value) {
        return;
      }
      emit('click', new LoginUserForm(form.email, form.password));
    };

    return {
      form,
      formValidated,

      throwEvent,
    };
  },
});
