import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0f53aae8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { style: {"font-size":"40px","margin-left":"20px"} }
const _hoisted_3 = { style: {"font-size":"16px","margin-bottom":"-25px"} }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  class: "captain"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(_ctx.isNewPrice)
  }, [
    (_ctx.image)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: require(`@/assets/images/Arrow_${_ctx.image}.png`),
          alt: "arrow",
          style: {"position":"absolute","margin-top":"8px","margin-left":"8px"}
        }, null, 8, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.basePrice) + "€", 1),
    _createElementVNode("p", _hoisted_3, [
      _createTextVNode(_toDisplayString(_ctx.priceMonth) + " ", 1),
      (_ctx.isNewPrice === 'newPrice')
        ? (_openBlock(), _createElementBlock("span", _hoisted_4, " €/mes "))
        : _createCommentVNode("", true)
    ]),
    (_ctx.isNewPrice === 'newPrice')
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.color)
          }, _toDisplayString(_ctx.discountName()), 3)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}