export default class CancelMotive {
  private $id: number;

  private $motive: string;

  constructor(id: number, motive: string) {
    this.$id = id;
    this.$motive = motive;
  }

  get id(): number {
    return this.$id;
  }

  get motive(): string {
    return this.$motive;
  }
}
