<template>
  <div>
    <div class="header">
      <div class="ambassadors-header">
        <h3>EMBAJADORES</h3>
        <p>PALABRA DE PRO</p>
      </div>
    </div>
    <!--  MOBILE-->
    <div v-if="resolution < 419" class="ambassadors">
      <swiper :spaceBetween="50" :mousewheel="true"
              :pagination="{
                el: '.swiper-pagination',
                clickable: true,
              }"
              class="pagination-slider" ref="mySwiper"
              options="swiperOptions" style="max-width: 360px" v-if="resolution<419">
        <div class="swiper-pagination ambassador-bullets" style="margin-left: 1px"></div>
        <swiper-slide class="swiper-slide">
          <AmbassadorCard
              image="PAU_card.png"
              name="PAU GASOL"
              description="“Me gustó comprobar que en INDYA, más allá de los datos, existen
               nutricionistas que asesoran al deportista en su día a día”."
          />
        </swiper-slide>
        <swiper-slide class="swiper-slide">
        <AmbassadorCard
              image="aleix.png"
              name="ALEIX ESPARGARÓ"
              description="“INDYA es igual de necesaria para un deportista profesional que para una
            persona que hace deporte para estar en forma”."
          />
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <AmbassadorCard
              image="andre.png"
              name="ANDRÉ RAMALHO"
              description="“Con INDYA entendí lo que realmente era tener una nutrición
              individualizada.
            Lo único que lamento es no haberlos conocido antes”."
          />
        </swiper-slide>
        <swiper-slide class="swiper-slide">
          <AmbassadorCard
              image="hector.png"
              name="HECTOR CABRERA"
              description="“Con INDYA no solo tienes lo que necesitas comer en cada momento,
            sino que tu nutricionista te ayuda a mantener la motivación bien alta”."
          />
        </swiper-slide>
      </swiper>
    </div>
    <!--  DESKTOP-->
    <div v-else class="ambassadors">
      <AmbassadorCard
          image="PAU_card.png"
          name="PAU GASOL"
          description="“Me gustó comprobar que en INDYA, más allá de los datos, existen
               nutricionistas que asesoran al deportista en su día a día”."
      />
      <AmbassadorCard
          image="aleix.png"
          name="ALEIX ESPARGARÓ"
          description="“INDYA es igual de necesaria para un deportista profesional que para una
            persona que hace deporte para estar en forma”."
      />
      <AmbassadorCard
          image="andre.png"
          name="ANDRÉ RAMALHO"
          description="“Con INDYA entendí lo que realmente era tener una nutrición individualizada.
            Lo único que lamento es no haberlos conocido antes”."
      />
      <AmbassadorCard
          image="hector.png"
          name="HECTOR CABRERA"
          description="“Con INDYA no solo tienes lo que necesitas comer en cada momento,
            sino que tu nutricionista te ayuda a mantener la motivación bien alta”."
      />
    </div>

    <button @click="scrollToTop">SELECCIONAR PLAN</button>

  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';

// eslint-disable-next-line import/extensions
import 'swiper/scss';
// eslint-disable-next-line import/extensions
import 'swiper/scss/navigation';
// eslint-disable-next-line import/extensions
import 'swiper/scss/pagination';
import AmbassadorCard from '@/components/molecules/AmbassadorCard.vue';
import SwiperCore, { Mousewheel, Pagination } from 'swiper';

export default {
  components: {
    AmbassadorCard,
    // eslint-disable-next-line vue/no-unused-components
    Swiper,
    // eslint-disable-next-line vue/no-unused-components
    SwiperSlide,
  },
  setup() {
    const resolution = document.documentElement.clientWidth;

    function scrollToTop() {
      window.scrollTo(0, 0);
    }

    SwiperCore.use([Mousewheel, Pagination]);

    return {
      resolution,
      scrollToTop,
    };
  },
};
</script>

<style lang="sass" scoped>
div
  @apply bg-white
  .header
  .ambassadors-header
    font-family: 'Montserrat'
    font-style: normal
    color: #171822
    height: 100px
    display: flex
    flex-direction: column
    justify-content: center

    h3
    font-weight: 500
    font-size: 12px
    line-height: 20px

    p:first-of-type
      font-weight: 700
      font-size: 24px
      line-height: 32px

  .ambassadors
    height: 660px
    @apply bg-white
    font-family: 'Montserrat'
    font-style: normal
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    .swiper
      width: 100%
      height: 100%
      .swiper-slide
        height: 80%
      .swiper-pagination-bullets
        background-color: transparent
        display: flex
        flex-direction: row
        justify-content: center
        align-content: center
        height: 20px
        width: 100%

  button
    padding: 0 40px
    gap: 10px
    width: 255px
    height: 43px
    background: #4CD2DB
    border-radius: 25px
    margin-bottom: 5em
</style>
