
import LabelValue from '@/domain/models/LabelValue';
import PlanCard from '@/domain/models/PlanCard';
import Subscription from '@/domain/models/Subscription';
import { computed, defineComponent, ref } from 'vue';
import { numberFormat } from '@/domain/services/numberHelper';
import PlanPriceWithCoupon from '@/components/molecules/PlanPriceWithCoupon.vue';
import Button from '../atoms/button.vue';

export default defineComponent({
  components: {
    PlanPriceWithCoupon,
    Button,
  },
  props: {
    planCard: {
      type: PlanCard,
      required: true,
    },
    variant: String,
    special: {
      type: Boolean,
      default: false,
    },
    price: {
      type: Number,
      required: true,
    },
    priceMonth: {
      type: Number,
      required: true,
    },
    priceMonthCoupon: {
      type: Number,
    },
    basePrice: {
      type: Number,
      required: true,
    },
    couponDuration: {
      type: Number,
      required: true,
    },
    couponAmountDiscount: {
      type: Number,
      required: true,
    },
    couponType: {
      type: String,
    },
    promo: {
      type: String,
      required: true,
    },
    paymentConditions: {
      type: String,
      required: true,
    },
    coupon: {
      type: Boolean,
      required: true,
    },
    planType: {
      type: Number,
      required: true,
    },
  },
  emits: ['selected'],
  setup(props) {
    const subscriptionValues = props
      .planCard
      .subscriptions
      .map((subscription: Subscription): LabelValue => {
        const {
          planType,
          id,
        } = subscription;
        return new LabelValue(planType, id);
      });
    const value = ref(subscriptionValues.slice()
      .reverse()[0].getValue);

    const selectedSubscription = computed(() => {
      if (props.planCard.subscriptions.length === 1) {
        return props.planCard.subscriptions[0];
      }

      return props.planCard.subscriptions
        .find((subscription: Subscription) => subscription.id === value.value);
    });

    const url = window.location.pathname.split('/')[1];

    return {
      subscriptionValues,
      selectedSubscription,
      value,
      url,

      numberFormat,
    };
  },
});
