export default class LabelValue {
  private label: string;

  private value: string;

  constructor(label: string, value: string) {
    this.label = label;
    this.value = value;
  }

  get getLabel(): string {
    return this.label;
  }

  get getValue(): string {
    return this.value;
  }
}
