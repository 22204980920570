export default class Discount {
  private $label: string;

  private $value: number;

  private $promoId: string;

  private $promotionCodeName: string;

  constructor(label: string, value: number, promoId: string, promotionCodeName: string) {
    this.$promotionCodeName = promotionCodeName;
    this.$label = label;
    this.$value = value;
    this.$promoId = promoId;
  }

  get label(): string {
    return this.$label;
  }

  get value(): number {
    return this.$value;
  }

  get promoId(): string {
    return this.$promoId;
  }

  get promotionCodeName(): string {
    return this.$promotionCodeName;
  }
}
