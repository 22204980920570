
import { defineComponent } from 'vue';
import EmailInput from './EmailInput.vue';
import NameInput from './NameInput.vue';

export default defineComponent({
  components: { NameInput, EmailInput },
  props: {
    name: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
});
