import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c249002"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "features" }
const _hoisted_2 = { class: "features-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FeatureCard = _resolveComponent("FeatureCard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, _toDisplayString(_ctx.TEXTO_FEATURES), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.TEXTOS_FEATURES, (feature, key) => {
      return (_openBlock(), _createBlock(_component_FeatureCard, {
        key: key,
        text: feature.feature_text,
        image: feature.feature_image,
        description: feature.feature_desciption
      }, null, 8, ["text", "image", "description"]))
    }), 128))
  ]))
}