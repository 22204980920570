import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TimeOutStep1 = _resolveComponent("TimeOutStep1")!
  const _component_TimeOutStep2 = _resolveComponent("TimeOutStep2")!
  const _component_TimeOutStep3 = _resolveComponent("TimeOutStep3")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.timeOut.Step1)
      ? (_openBlock(), _createBlock(_component_TimeOutStep1, {
          key: 0,
          "elements-included": _ctx.elementsIncluded,
          "elements-not-included": _ctx.elementsNotIncluded,
          elementsFeatured: _ctx.elementsFeatured,
          onActivateStep3: _ctx.activateStep3,
          onTimeOutPlanPurchase: _ctx.timeOutPlanPurchase,
          onCancel: _ctx.cancel,
          onExitSteps: _ctx.exitSteps
        }, null, 8, ["elements-included", "elements-not-included", "elementsFeatured", "onActivateStep3", "onTimeOutPlanPurchase", "onCancel", "onExitSteps"]))
      : _createCommentVNode("", true),
    (_ctx.timeOut.Step2)
      ? (_openBlock(), _createBlock(_component_TimeOutStep2, {
          key: 1,
          "elements-included": _ctx.elementsIncluded,
          "elements-not-included": _ctx.elementsNotIncluded,
          elementsFeatured: _ctx.elementsFeatured,
          currentSubscription: _ctx.currentSubscription,
          onUpdateProfile: _ctx.updateProfile,
          onExitSteps: _ctx.exitSteps
        }, null, 8, ["elements-included", "elements-not-included", "elementsFeatured", "currentSubscription", "onUpdateProfile", "onExitSteps"]))
      : _createCommentVNode("", true),
    (_ctx.timeOut.Step3)
      ? (_openBlock(), _createBlock(_component_TimeOutStep3, {
          key: 2,
          "elements-included": _ctx.elementsIncluded,
          "elements-not-included": _ctx.elementsNotIncluded,
          elementsFeatured: _ctx.elementsFeatured,
          onDesactivateStep3: _ctx.desactivateStep3
        }, null, 8, ["elements-included", "elements-not-included", "elementsFeatured", "onDesactivateStep3"]))
      : _createCommentVNode("", true)
  ], 64))
}