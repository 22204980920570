
import { defineComponent, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { numberFormat } from '@/domain/services/numberHelper';
import Subscription from '@/domain/models/Subscription';
import PlanCard from '@/domain/models/PlanCard';
import {
  ANUAL, SEMESTRAL, MENSUAL, EQUIVALE_A, DESPUES, PAGO_UNICO, PRIMER_MES,
} from '@/constants/texts.es';

export default defineComponent({
  components: {
  },
  props: {
    planCard: {
      type: PlanCard,
      require: true,
    },
    subscription: {
      type: Subscription,
      require: true,
    },
    promo: {
      type: String,
      require: false,
    },
    price: {
      type: String,
      require: true,
    },
    priceMonth: {
      type: String,
      require: true,
    },
    basePrice: {
      type: String,
      require: true,
    },
  },
  emits: ['selected'],
  setup(props, { emit }) {
    const store = useStore();
    const currentSubscription = computed(() => store.getters.currentSubscription);

    let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    onMounted(() => {
      isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    });

    const url = window.location.pathname.split('/')[1];

    const checkBasicPlanPromo = () => {
      if ((props.subscription?.couponAmountDiscount === 0
      || props.subscription?.couponAmountDiscount === null)
      && props.promo === null
      && props.subscription.$couponDuration === 0
      && props.subscription.name === 'AMAPRO'
      ) {
        return false;
      }

      return true;
    };

    const discountStyle = () => {
      if (props.subscription?.planType === '12 MESES' && (props.subscription?.promo || props.promo)) {
        return 'plan-discount promo-12-months';
      }
      if (props.subscription?.planType === '6 MESES' && (props.subscription?.promo || props.promo)) {
        return 'plan-discount promo-6-months';
      }
      if (props.subscription?.period) {
        return 'plan-discount period';
      }
      return 'plan-discount no-promo';
    };

    const planType = () => {
      if (props.subscription?.planType === '12 MESES') {
        return ANUAL;
      }

      if (props.subscription?.planType === '6 MESES') {
        return SEMESTRAL;
      }

      return MENSUAL;
    };

    const afterPriceText = () => {
      if (props.subscription?.$couponAmountDiscount === 0 && props.promo === null) {
        return '';
      }

      if (props.subscription?.period) {
        return props.subscription?.period;
      }

      if (props.subscription?.name === 'AMAPRO') {
        return PRIMER_MES;
      }

      return PAGO_UNICO;
    };

    const isSamePlan = () => {
      if (currentSubscription.value === null) {
        return true;
      }

      if (props.subscription?.basePrice === currentSubscription.value.lastPayOriginalValue) {
        return false;
      }

      return true;
    };

    const priceMonthAfter = () => {
      if (isMobile && props.subscription?.name === 'AMAPRO') {
        return `${props.basePrice}€/mes`;
      }

      if (!checkBasicPlanPromo()) {
        return '';
      }

      if (props.subscription?.name === 'AMAPRO') {
        return `${props.basePrice}€/mes`;
      }

      return `${props.priceMonth}`;
    };

    const nextPaymentText = () => {
      if (props.subscription?.planType === '12 MESES' || props.subscription?.planType === '6 MESES') {
        return `${EQUIVALE_A}`;
      }

      if (isMobile) {
        return `${DESPUES}`;
      }

      if (!checkBasicPlanPromo()) {
        return '';
      }

      return `${DESPUES}`;
    };

    const promoText = () => {
      if (!checkBasicPlanPromo()) {
        return !isMobile ? 'SELECCIONAR' : '';
      }

      if (props.subscription?.couponNewPromo && props.subscription?.name === 'AMAPRO') {
        return props.subscription.couponNewPromo;
      }

      if (props.subscription?.promo) {
        return props.subscription?.promo;
      }

      if (props.promo) {
        return `${props.promo}`;
      }

      if (props.subscription?.newPromo) {
        return `${props.subscription?.newPromo}`;
      }

      return !isMobile ? 'SELECCIONAR' : '';
    };

    function changePlan(): boolean {
      if (window.location.pathname.indexOf('change-plan') !== -1) {
        return true;
      }

      return false;
    }

    function planSelected(): void {
      if (changePlan()) {
        emit('selected', props.subscription);
      } else {
        emit('selected', props.planCard?.$id, props.subscription?.id, props.subscription?.name);
      }
    }

    return {
      url,
      isMobile,

      numberFormat,
      discountStyle,
      planSelected,
      planType,
      nextPaymentText,
      isSamePlan,
      priceMonthAfter,
      promoText,
      afterPriceText,
      checkBasicPlanPromo,
    };
  },
});
