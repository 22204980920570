<template>
  <div class="column">
    <div class="title">
      <slot />
    </div>
    <div class="content">
      <slot name="content" />
    </div>
  </div>
</template>

<style lang="sass" scoped>
  .column
    @apply text-white text-left font-medium
    .title
      @apply mb-7
    .content
      @apply text-md
      &:deep ul li
        @apply mb-2 flex gap-3 items-start
        img
          @apply mt-1

      &:deep > img
        @apply inline-block pr-7 pb-5
</style>
