<template>
  <div class="cta"
       :style="{
        backgroundImage:
        'url(' + require('@/assets/images/bloqueInferior.png') + ')'
    }"
  >
    <div class="cta-content">
      <h3>PERSONALIZA TU ALIMENTACIÓN</h3>
      <p>Empieza a darle a tu cuerpo lo que necesita en cada momento
        y comprueba la influencia de la nutrición en tu rendimiento deportivo.</p>
      <button @click="scrollToTop" >EMPIEZA UN RETO</button>
    </div>
  </div>
</template>

<script setup>

function scrollToTop() {
  window.scrollTo(0, 0);
}
</script>

<style lang="sass" scoped>
.cta
  min-height: 484px
  width: 100%
  @apply bg-[length:484px] bg-no-repeat bg-center bg-cover sm:bg-[length:100%_100%]
  font-family: 'Montserrat'
  font-style: normal
  color: #171822
  background-attachment: initial
  background-position: bottom center
  background-size: cover
  .cta-content
    @apply flex justify-center flex-col items-center
    height: 260px
    h3
      @apply mt-3 sm:mt-0 font-bold text-2xl leading-8

    p
      @apply max-w-xs sm:max-w-xl
      font-weight: 400
      font-size: 16px
      line-height: 25px
      margin: 2em 0
      text-align: center

    button
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      padding: 2px 40px
      gap: 10px
      width: 235px
      height: 43px
      background: #4CD2DB
      border-radius: 25px
</style>
