/* eslint camelcase: ["error", {allow: [".*"]}] */

export type RawSubscriptions = {
  fare_id: string
  fare_name: string
  plan_type: string
  coupon_type: string|null
  fare_price_per_month_coupon: number|null
  fare_value: number
  fare_price_per_month: string
  fare_original_price: number
  coupon_duration: number
  coupon_amount_discount: number
  fare_payment_conditions: string
  conditions: string
  promo: string
  stripe_product_id: string
  fare_includes_landing: Array<string>
  fare_highlighted_features: Array<string>
  fare_not_includes: Array<string>
  fare_includes: Array<string>
  new_promo: string
  active_plan: boolean
  period: string|null,
  coupon_new_promo: string|null
}

export type RawPlanCard = {
  fare_id: string,
  fare_name: string
  fare_value: number
  fare_text: string
  packs: RawSubscriptions[]
}

export type RawPlanCardTimeOut = {
  fare_id: string,
  fare_name: string
  fare_value: number
  fare_text: string
  fare_highlighted_features: Array<string>
  fare_not_includes: Array<string>
  fare_includes: Array<string>
  packs: RawSubscriptions[]
}

export type RawWrapperTimeOut = {
  components: { options: RawPlanCardTimeOut[] }
}

export type RawWrapper = {
  components: { options: RawPlanCard[] }
}

export type loginData = {
  data: {
    data: {
      authentication_token: string
    }
  }
}

export type RawCancelMotive = {
  reason: string,
  subscription_cancellation_reason_id: number
}

export type Navigation = {
  onboarding: {
      fix_recipes: boolean,
      thankyou: boolean,
      challenge: boolean,
      payment: boolean,
      upgrade: boolean
  },
  screen: string,
}

export const DEFAULT_NAVIGATION = {
  onboarding: {
    fix_recipes: false,
    thankyou: false,
    challenge: false,
    payment: false,
    upgrade: false,
  },
  screen: 'onboarding',
};

export type RawBasicInfo = {
  activeSubscriptionName: string|null,
  client_id: number,
  invitation_name: string,
  plan: string|null,
  subscriptionStatus: string|null
  navigation: Navigation
  username: string
  userImage: string
}

export type RawPromoCode = {
  finalPrice: number,
  promotionCodeId: string,
  promotionCodeName: string
}

export type RawCurrentSubscription = {
  last_pay_type: string,
  last_pay_date: string,
  last_pay_finale_value: string,
  last_pay_original_value: string,
  next_pay_type: string,
  next_pay_date: string,
  next_pay_original_value: string,
  next_pay_finale_value: string,
  stripe_product_id: string,
  last_pay_discounts: Array<string>,
  next_pay_discounts: Array<string>,
}

export type ManualUpdate = {
  text: string,
  title: string,
}

export type RawUserSubscription = {
  created_at: string;
  updated_at: string;
  valid_from: string;
  valid_to: string;
  platform: string;
}

export enum CardColor {
  AMATEUR = 'turquoise-card.svg',
  AMAPRO = 'purple-card.svg'
}

export const USER_PAID = DEFAULT_NAVIGATION.onboarding.payment;

export enum Steps {
  Registration,
  Payment,
  Done,
}

// BEGIN: Monthly Report Types

export interface MonthlyReportResultInfo {
  resultCode: number
  resultDescription: string
}

export interface RecipeFulfillmentWeekly {
  label: string
  value: number
}

export interface RecipeFulfillmentMonthlyHistory {
  label: string
  value: number | string | boolean
}

export interface AchievementsDailyValue {
  fulfillment_5: number
  perfect_day: number
  fulfillment_5_in_row: number
  col: string
  row: number
}

export interface AchievementsDaily {
  label: string
  value: AchievementsDailyValue
}

export interface BestRecipe {
  image: string
  positive: number
  total: number
  efficacy: number
}

export interface MonthlyReportData {
  date: string
  athlete_name: string
  athlete_image_url: string
  sport_name: string
  sport_image_url: string
  recipe_fulfillment_percent: number
  recipe_fulfillment_5: number
  recipe_fulfillment_mmps: number
  recipe_fulfillment_weekly: RecipeFulfillmentWeekly[]
  recipe_fulfillment_monthly_history: RecipeFulfillmentMonthlyHistory[]
  recipe_fulfillment_yearly_average: number
  recipe_fulfillment_yearly_step: string
  achievements_fulfillment_5_mmp: number
  achievements_fulfillment_5_mmp_date: string
  achievements_fulfillment_5_general_top: number
  achievements_fulfillment_5_sport_top: number
  achievements_fulfillment_5_in_row_mmp: number
  achievements_fulfillment_5_in_row_mmp_date: string
  achievements_fulfillment_5_in_row_general_top: number
  achievements_fulfillment_5_in_row_sport_top: number
  achievements_perfect_days_mmp: number
  achievements_perfect_days_mmp_date: string
  achievements_perfect_days_general_top: number
  achievements_perfect_days_sport_top: number
  achievements_fulfillment_5: number
  achievements_fulfillment_5_in_row: number
  achievements_perfect_days: number
  achievements_daily: AchievementsDaily[]
  best_recipes_high_intensity: BestRecipe[]
  best_recipes_low_intensity: BestRecipe[]
}
export interface MonthlyReportResponse {
  result: MonthlyReportResultInfo
  data: MonthlyReportData
  notification: []
}

// END: Monthly Report Types
