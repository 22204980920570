export function validatePassword(password: string): boolean {
  return password.length >= 8;
}

export function validateEmail(email: string): boolean {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,8})+$/.test(email);
}

export function validateDNI(dni: string): boolean {
  let numero: number;
  let letr;
  let letra;
  const expresionRegularDNI = /^\d{8}[a-zA-Z]$/;

  if (expresionRegularDNI.test(dni) === true) {
    numero = parseInt(dni.substr(0, dni.length - 1), 10);
    letr = dni.substr(dni.length - 1, 1);
    numero %= 23;
    letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
    letra = letra.substring(numero, numero + 1);
    return letra === letr.toUpperCase();
  }

  return false;
}

export function validateEmpty(text: string): boolean {
  return text.trim().length === 0;
}

export function validateNameField(text: string): string {
  if (text.trim() === '') {
    return 'Nombre no puede estar vacío';
  }
  return '';
}

export function validateEmailField(text: string): string {
  if (text.trim() === '') {
    return 'EMail no puede estar vacío';
  }
  if (!validateEmail(text)) {
    return 'No parece un email correcto';
  }
  return '';
}

export function validatePasswordField(text: string): string {
  if (text.trim() === '') {
    return 'Contraseña no puede estar vacía';
  }
  if (!validatePassword(text)) {
    return 'Necesitas mínimo ocho caracteres';
  }
  return '';
}

export function validateCardNumberField(text: string): string {
  if (text.trim() === '') {
    return 'El número de la tarjeta no puede estar vacía';
  }
  if (!/^[0-9]{13,19}$/.test(text)) {
    return 'El número de la tarjeta es incorrecto';
  }
  return '';
}

export function validateCardExpireField(text: string): string {
  if (text.trim() === '') {
    return 'La expiración no puede estar vacía';
  }
  if (!/^[0-9]+\/[0-9]{2}$/.test(text)) {
    return 'La expiración es incorrecta';
  }
  const [month, year] = text.split('/');
  const d = new Date(parseInt(`20${year}`, 10), parseInt(month, 10));
  const now = new Date();
  if (d < now) {
    return 'La expiración es incorrecta';
  }
  return '';
}

export function validateCVVField(text: string): string {
  if (text.trim() === '') {
    return 'El CVV no puede estar vacío';
  }
  if (!/^[0-9]{3,}$/.test(text)) {
    return 'El CVV es incorrecta';
  }
  return '';
}
