import store from '@/store';
import axios from 'axios';
import router from '@/router';

const customAxios = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

customAxios.interceptors.request.use(
  (config) => {
    if (config.headers) {
      if (store.getters.loggedUser) {
        const { token } = store.getters;
        config.headers.Authorization = `Basic ${token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error),
);

const UNAUTHORIZED = 401;
customAxios.interceptors.response.use(
  (res) => res,
  (error) => {
    const responseStatus = error.response.data.code;
    const responseMessage = error.response.data.message;
    const expiredJWT = responseStatus === UNAUTHORIZED && responseMessage === 'Expired JWT Token';

    if (responseStatus !== UNAUTHORIZED) {
      return new Promise((_, reject) => {
        reject(error);
      });
    }

    if (expiredJWT) {
      store.dispatch('refreshToken')
        .then(() => {
          router.go(0);
          return Promise.resolve();
        })
        .catch((errorOnExpired) => {
          console.log(errorOnExpired);
          router.push({ name: 'selectAuth' });
          return Promise.reject(errorOnExpired);
        });
    }

    return Promise.reject(error);
  },
);

export default customAxios;
