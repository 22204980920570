import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "price-block" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "price-before-discount" }
const _hoisted_4 = { class: "price-after-discount" }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.priceWithDiscount)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.priceBeforeDiscount) + "€", 1),
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.couponColor, "discount-applied"])
          }, _toDisplayString(_ctx.couponApplied), 3),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.priceAfterDiscount.toFixed(2)) + "€", 1)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.priceBeforeDiscount) + " €", 1))
  ]))
}