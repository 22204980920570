export default class RegisterUserForm {
  $email: string;

  $password: string;

  constructor(email: string, password: string) {
    this.$email = email;
    this.$password = password;
  }

  get email(): string {
    return this.$email;
  }

  get password(): string {
    return this.$password;
  }
}
