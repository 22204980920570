import { createApp } from 'vue';
import VueLoading from 'vue-loading-overlay';
import VueUniversalModal from 'vue-universal-modal';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/css/tailwind.css';
import 'vue-loading-overlay/dist/vue-loading.css';
import 'vue-universal-modal/dist/index.css';

createApp(App)
  .use(store)
  .use(router)
  .use(VueLoading)
  .use(VueUniversalModal, {
    teleportTarget: '#modals',
    modalComponent: 'ModalComponent',
  })
  .mount('#app');
