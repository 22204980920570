import CurrentSubscription from '@/domain/models/CurrentSubscription';
import PlanCard from '@/domain/models/PlanCard';
import User from '@/domain/models/User';
import { getDefaultState, State } from '@/store/state';
import { Mutations } from 'vuex-smart-module';
import { MonthlyReportData, USER_PAID } from '@/domain/services/types';

export default class extends Mutations<State> {
  setAuth(token: string) {
    localStorage.setItem(process.env.VUE_APP_ACCESS_TOKEN, token);
    this.state.auth.token = token;
  }

  logout() {
    localStorage.removeItem(process.env.VUE_APP_ACCESS_TOKEN);
    this.state.auth.token = null;
    this.state.user = null;
  }

  setUser(user: User) {
    this.state.user = user;
  }

  forceUserHasPlan() {
    if (!this.state.user) {
      return;
    }
    this.state.user.navigation.onboarding.payment = !USER_PAID;
  }

  setCurrentSubscription(currentSubscription: CurrentSubscription) {
    this.state.currentSubscription = currentSubscription;
  }

  unsetCurrentSubscription() {
    this.state.currentSubscription = null;
  }

  removeAuth() {
    localStorage.removeItem(process.env.VUE_APP_ACCESS_TOKEN);
    Object.assign(this.state, getDefaultState());
  }

  setPlans(plans: PlanCard[]) {
    this.state.plans = plans;
  }

  setTimeOutPlan(plans: PlanCard[]) {
    this.state.planTimeOut = plans;
  }

  setCoupon(coupon: string) {
    this.state.coupon = coupon;
  }

  setMonthlyReport(monthlyReport: MonthlyReportData): void {
    this.state.monthlyReport = monthlyReport;
  }
}
