import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9ca7d48e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "forgot-password-form" }
const _hoisted_2 = { class: "input" }
const _hoisted_3 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EmailInput = _resolveComponent("EmailInput")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_EmailInput, {
        modelValue: _ctx.email,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
        placeholder: "Email",
        onEnter: _ctx.throwEvent
      }, null, 8, ["modelValue", "onEnter"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Button, {
        onClick: _ctx.throwEvent,
        disabled: !_ctx.formValidated,
        variant: "turquoise"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Enviar enlace ")
        ]),
        _: 1
      }, 8, ["onClick", "disabled"])
    ])
  ]))
}