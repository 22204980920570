
import CancelMotive from '@/domain/models/CancelMotive';
import { defineComponent, PropType } from 'vue';
import Button from '../atoms/button.vue';
import Modal from '../atoms/Modal.vue';

export default defineComponent({
  components: { Button, Modal },
  props: {
    motives: {
      type: Array as PropType<Array<CancelMotive>>,
      required: true,
    },
    modelValue: {
      type: Number,
      default: -1,
    },
  },
  emits: ['next', 'cancel', 'update:modelValue'],
  setup() {
    const throwEvent = (target: EventTarget | null): number => {
      if (!target) {
        return -1;
      }
      return parseInt((target as HTMLInputElement).value, 10);
    };
    return {
      throwEvent,
    };
  },
});
