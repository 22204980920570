import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import SelectPlan from '@/views/SelectPlan.vue';
import SelectPlanLegacy from '@/views/SelectPlanLegacy.vue';
import Register from '@/views/Register.vue';
import Login from '@/views/Login.vue';
import Profile from '@/views/Profile.vue';
import ChangePlan from '@/views/ChangePlan.vue';
import store from '@/store';
import getCouponCode from '@/domain/services/couponHelper';
import PromoSelectPlan from '@/views/PromoSelectPlan.vue';
import PromoRegister from '@/views/PromoRegister.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/plan',
    name: 'select-plan',
    component: SelectPlan,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
  },
  {
    path: '/login/:subscriptionId?/:planId?',
    name: 'login',
    component: Login,
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/change-plan/:plan',
    name: 'change-plan',
    component: ChangePlan,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/promo',
    name: 'promo',
    component: PromoSelectPlan,
  },
  {
    path: '/promo-login',
    name: 'promo-login',
    component: PromoRegister,
  },
  {
    path: '/report/:uuid',
    name: 'report',
    component: () => import('@/views/report/Report.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const coupon = getCouponCode(to.query);
  if (coupon) {
    store.commit('setCoupon', coupon);
  }

  if (to.meta.requiresAuth && !store.getters.token) {
    next({ name: 'select-plan' });
  }

  if (to.meta.requiresAuth && store.getters.token === null) {
    next({ name: 'select-plan' });
  }

  if (store.getters.user === null && (to.meta.requiresAuth || store.getters.token !== null)) {
    await store.dispatch('userBasicInfo').catch((err) => {
      console.log(err);
    });
  }

  if (to.matched.length === 0) {
    next({ name: 'select-plan' });
  }

  next();
});

export default router;
