<template>
  <form>
    <div class="cards">
      <img :src="require('@/assets/images/cards.svg')" alt="cards">
    </div>
    <div class="card-1">
      <div
        id="card"
        class="card-line card-number"
        :style="'background-image:url('+ require('@/assets/images/' + cardImage.value) +')'"
      />
    </div>
    <div class="card-2">
      <RegularInput
        v-model="card.name"
        class="card-name"
        placeholder="Nombre en la tarjeta"
        :error="errorName"
        @input="validateForm"
        @blur="errorName = validateNameField($event.modelValue)"
        v-on:focusin="cardImage.value = 'password.png'"
      />
    </div>
    <div class="error" v-if="error !== ''">{{ error }}</div>
    <Discount
      v-model="card.discount"
      :error="discountError"
      :appliedDiscount="appliedDiscount"
      @apply="$emit('apply-discount', $event)"
    />
    <div class="item-set">
      <div>
        <span class="image">
          <img :src="require('@/assets/images/calendar.svg')" alt="mes">
        </span>
        <span>
          Tu suscripción mensual se renovará <strong>automáticamente</strong>.
          Para cancelar el próximo pago deberás hacerlo al menos 24h antes de
          la fecha de renovación.
        </span>
      </div>
      <div>
        <span class="image">
          <img :src="require('@/assets/images/password-white.png')" alt="seguridad">
        </span>
        <span>
          INDYA no guardará tus datos de pago. Tus datos estarán protegidos por Stripe.
          Leer <a href="#">política de seguridad de Stripe</a>
        </span>
      </div>
    </div>
    <div class="button">
      <Button
      variant="turquoise"
      :disabled="!formValidated"
      @click="pay"
    >
      Activar Plan
      {{ selectedPlan.name }}
      {{ selectedSubscription.$planType }}
      por <span class="price" v-if="appliedDiscount && discountAmount >= 0">
        {{ discountAmount }}
      </span><span class="price" v-else>
        {{ selectedSubscription.price }}
      </span>€
    </Button>
    </div>
  </form>
</template>

<script>
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
} from 'vue';
import PlanCard from '@/domain/models/PlanCard';
import Subscription from '@/domain/models/Subscription';
import {
  validateNameField,
} from '@/domain/services/validationHelper';
import useAmplitude from '@/composables/useAmplitude';
import { useRoute, useRouter } from 'vue-router';
import RegularInput from '../molecules/RegularInput.vue';
import Button from '../atoms/button.vue';
import Discount from './Discount.vue';

export default defineComponent({
  components: {
    RegularInput,
    Button,
    Discount,
  },
  props: {
    selectedPlan: {
      type: PlanCard,
      required: true,
    },
    selectedSubscription: {
      type: Subscription,
      required: true,
    },
    appliedDiscount: {
      type: Boolean,
      default: false,
    },
    discountError: {
      type: Boolean,
      default: false,
    },
    discountAmount: {
      type: Number,
      default: 0,
    },
    discountCode: {
      type: String,
      default: '',
    },
    error: String,
    couponUsed: {
      type: [String, null],
      required: true,
    },
  },
  emits: ['promoPay', 'pay', 'apply-discount'],
  setup(props, { emit }) {
    const card = reactive({
      token: '',
      discount: props.discountCode,
      name: '',
    });
    const formValidated = ref(false);
    const stripeValidation = ref(false);
    const router = useRouter();

    const errorName = ref('');

    const validateForm = () => {
      formValidated.value = validateNameField(card.name) === ''
        && stripeValidation.value;
    };

    const stripe = new (window).Stripe(process.env.VUE_APP_STRIPE_KEY);
    let cardLine = null;
    const cardImage = reactive({
      value: 'password.png',
    });

    onMounted(() => {
      const elements = stripe.elements({
        fonts: [{
          cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600',
        }],
      });
      useAmplitude()?.logEvent('Onboarding_pay_screen');
      window.dataLayer.push({
        event: 'virtual_pageview_pay',
      });
      const style = {
        base: {
          iconColor: '#c4f0ff',
          color: 'white',
          fontFamily: 'Montserrat, Helvetica',
          fontSize: '14px',
        },
        invalid: {
          color: 'white',
        },
      };
      const classes = {
        base: 'border-b border-gray fill-current',
        invalid: 'border-b border-red',
        focus: 'border-b border-white fill-current',
      };
      cardLine = elements.create('card', {
        classes,
        style,
        hidePostalCode: true,
        hideIcon: true,
      });
      cardLine.mount('#card');
      cardLine.on('change', (ev) => {
        cardImage.value = 'password-white.png';
        stripeValidation.value = ev.complete;
        validateForm();
      });
    });

    function buttonPayActivateDesactivate() {
      formValidated.value = !formValidated.value;
    }

    function isPromo() {
      if (router.currentRoute.value.name === 'register') {
        return false;
      }
      return true;
    }

    const pay = () => {
      buttonPayActivateDesactivate();
      stripe.createToken(cardLine, { name: card.name })
        .then((result) => {
          if (result.token) {
            card.token = result.token;
            if (isPromo()) {
              emit('promoPay', card, stripe);
              buttonPayActivateDesactivate();
            } else {
              emit('pay', card, stripe);
              buttonPayActivateDesactivate();
            }
            window.dataLayer.push({
              event: 'purchase',
              ecommerce: {
                transaction_id: Date.now() + Math.random(),
                currency: 'EUR',
                value: props.appliedDiscount && props.discountAmount >= 0
                  ? props.discountAmount : props.selectedSubscription.price,
                coupon: props.couponUsed,
                items: [{
                  item_name: props.selectedSubscription.name,
                  item_id: props.selectedSubscription.id,
                  discount: props.selectedSubscription.promo,
                  price: props.appliedDiscount && props.discountAmount >= 0
                    ? props.discountAmount : props.selectedSubscription.price,
                }],
              },
            });
          }
        });
    };

    return {
      card,
      errorName,
      formValidated,
      cardImage,

      validateNameField,
      validateForm,
      pay,
    };
  },
});
</script>

<style lang="sass" scoped>
  form
    @apply p-5 bg-secondary-gray rounded-lg text-sm
    .cards
      @apply flex w-full justify-end mb-7
    .card-1
      @apply flex gap-3 px-3 items-end mb-5
      .card-number
        @apply flex-auto w-3/5 bg-no-repeat bg-left-top pt-2 pb-2 pl-7
        :deep(img)
          height: 21px
        :deep(input)
          @apply placeholder-fourth-gray
      :deep(.label)
        @apply text-fourth-gray
      .card-expire, .card-cvv
        @apply flex-auto w-1/5 pt-2 pb-2 pl-2
        :deep(input)
          @apply placeholder-fourth-gray
    .card-2
      @apply px-3
      :deep(.label)
        @apply text-fourth-gray
      :deep(.group input)
        @apply placeholder-fourth-gray
    .error
      @apply text-sm text-red text-center mb-5
    .discount
      @apply shadow-[0_4px_12px_rgba(0,0,0,0.2)]
      :deep(.label)
        @apply text-fourth-gray
      :deep(.group input)
        @apply placeholder-fourth-gray
    .item-set
      @apply text-fourth-gray text-xxs mb-10
      > div
        @apply flex text-left gap-3 justify-between w-full mb-3
        span
          @apply grow
          &.image
            @apply grow-0 shrink-0 w-6 text-center
            img
              @apply inline
        a, strong
          @apply font-medium
    .button
      @apply max-w-sm mx-auto
      .price
        @apply text-lg
</style>
