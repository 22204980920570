import Subscription from '@/domain/models/Subscription';

export default class PlanCard {
  $id: string;

  $name: string;

  $description: string;

  $subscriptions: Subscription[];

  constructor(
    id: string,
    name: string,
    description: string,
    subscriptions: Subscription[],
  ) {
    this.$id = id;
    this.$name = name;
    this.$description = description;
    this.$subscriptions = subscriptions;
  }

  get id(): string {
    return this.$id;
  }

  get name(): string {
    return this.$name;
  }

  get description(): string {
    return this.$description;
  }

  get subscriptions(): Subscription[] {
    return this.$subscriptions;
  }

  get mainSubscription(): Subscription {
    const i = Math.floor(this.subscriptions.length / 2);
    return this.subscriptions[i];
  }
}
