
import
{
  defineComponent, onMounted, reactive, ref,
}
  from 'vue';
import LoginForm from '@/components/organisms/LoginForm.vue';
import LoginUserForm from '@/domain/models/LoginUserForm';
import NavigationRedirector from '@/domain/services/NavigationRedirector';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useLoading } from 'vue-loading-overlay';
import Button from '@/components/atoms/button.vue';
import Modal from '@/components/atoms/Modal.vue';
import useAmplitude from '@/composables/useAmplitude';
import ForgotPasswordForm from '../components/organisms/ForgotPasswordForm.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    LoginForm,
    Button,
    Modal,
    ForgotPasswordForm,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const error = ref();
    const loading = useLoading({ backgroundColor: 'black', color: 'white' });
    const modal = reactive({
      forgotEmail: false,
      forgotPassword: false,
      emailNotFound: false,
      emailSent: false,
    });
    if (store.getters.loggedUser) {
      if (!NavigationRedirector.userHasPayedSubscription(store.getters.user)) {
        router.push({ name: 'profile' });
      }
    }

    const loginUser = (form: LoginUserForm) => {
      const loader = loading.show();
      error.value = null;
      store.dispatch('login', form)
        .then(() => {
          store.dispatch('userBasicInfo')
            .then(() => {
              if (typeof route.query.utm_campaign !== 'undefined') {
                router.push({
                  name: 'promo-login',
                  query: {
                    ...route.query,
                    data: route.query.data,
                    planId: route.query.planId,
                    subscriptionId: route.query.subscriptionId,
                  },
                });
                return;
              }
              if (NavigationRedirector.userHasPayedSubscription(store.getters.user)) {
                router.push({ name: 'select-plan' });
                return;
              }
              router.push({ name: 'profile' });
            });
        })
        .catch(() => {
          error.value = 'El email o la contraseña son incorrectos';
        })
        .finally(() => {
          loader.hide();
        });
    };

    const resetEmail = (ev: Event) => {
      const email = ev;
      const loader = loading.show();
      store.dispatch('resetEmail', email)
        .then(() => {
          modal.emailSent = true;
        })
        .catch(() => {
          modal.emailNotFound = true;
        })
        .finally(() => {
          modal.forgotPassword = false;
          loader.hide();
        });
    };

    const contactSupport = () => {
      modal.emailNotFound = false;
      window.open(process.env.VUE_APP_SUPPORT, '_blank');
    };

    return {
      error,
      modal,

      loginUser,
      resetEmail,
      contactSupport,
    };
  },
});
