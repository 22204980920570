
import { defineComponent, onMounted } from 'vue';
import Button from '@/components/atoms/button.vue';
import ListElement from '@/components/atoms/ListElement.vue';
import Subscription from '@/domain/models/Subscription';
import useAmplitude from '@/composables/useAmplitude';

export default defineComponent({
  components: { ListElement, Button },
  emits: ['updateProfile'],
  props: {
    elementsIncluded: {
      type: [],
      required: true,
    },
    elementsNotIncluded: {
      type: [],
      required: true,
    },
    elementsFeatured: {
      type: [],
      required: true,
    },
    currentSubscription: {
      type: Subscription,
    },
  },
  setup() {
    onMounted(() => {
      useAmplitude()?.logEvent('Wellcome_screen');
      (window as any)?.dataLayer.push({
        event: 'Wellcome_screen',
      });
    });
  },
});
