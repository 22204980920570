// Header
export const HEADER_TITLE_1 = 'No es dieta, es deporte.';
export const HEADER_TITLE_2 = 'El rendimiento es un hábito de vida';
// Select Plan
export const PRECIOS_SUSCRIPCION = 'Precios suscripción';
export const TEXTO_PACKS = 'Con las opciónes de 6 o 12 meses eliges abonar el precio total en un único pago por adelantado. Estas opciónes no permite la devolución total o parcial si decides dejar el servicio antes de los 6 o 12 meses respectivamente.';
// PlanCard constants
export const ANUAL = 'ANUAL';
export const SEMESTRAL = 'SEMESTRAL';
export const MENSUAL = 'MENSUAL';
export const EQUIVALE_A = 'Equivale a';
export const DESPUES = 'Después';
export const PAGO_UNICO = 'Pago único';
export const PRIMER_MES = 'PRIMER MES';
// FeaturesCards
export const TEXTO_FEATURES = 'TU SUSCRIPCIÓN INCLUYE';
export const TEXTOS_FEATURES = [
  {
    feature_text: 'PLAN NUTRICIONAL',
    feature_image: 'plan-nutricional.svg',
    feature_desciption: 'Planificación ultrapersonalizada de todas las comidas de cada semana.',
  },
  {
    feature_text: 'CAMBIO INTELIGENTE DE RECETA',
    feature_image: 'cambio-inteligente-de-receta.svg',
    feature_desciption: 'Elige otras alternativas sin alterar ni una caloría tu arquitectura nutricional.',
  },
  {
    feature_text: 'REAJUSTES AUTOMÁTICOS',
    feature_image: 'reajustes-automaticos.svg',
    feature_desciption: 'Cambia lo que necesites y tu nutrición se reajustará automáticamente.',
  },
  {
    feature_text: 'LISTA DE LA COMPRA',
    feature_image: 'lista-de-la-compra.svg',
    feature_desciption: 'El lujo de comprar con la lista hecha de todo lo que necesitas. Ni más, ni menos.',
  },
  {
    feature_text: 'GRÁFICOS DE EVOLUCIÓN',
    feature_image: 'grafico-evolucion.svg',
    feature_desciption: 'La nutrición ya no es invisible. Comparte tu mejora con tus amigos y enemigos.',
  },
  {
    feature_text: 'CAMBIO DE ENTRENAMIENTOS',
    feature_image: 'cambio-entrenamientos.svg',
    feature_desciption: 'Si tus entrenamientos cambian, tu nutrición se reajusta automáticamente.',
  },
  {
    feature_text: 'VIDEOCONSULTA',
    feature_image: 'videoconsulta.svg',
    feature_desciption: 'Si tus entrenamientos cambian, tu nutrición se reajusta automáticamente.',
  },
  {
    feature_text: 'CHAT CON TU NUTRICIONISTA',
    feature_image: 'chat-nuticionista.svg',
    feature_desciption: 'Escribe a tu nutricionista  cuando tengas dudas o necesites algún cambio.',
  },
  {
    feature_text: 'SINCRONIZACIÓN',
    feature_image: 'sincronizacion.svg',
    feature_desciption: 'Sincroniza INDYA con tus dispositivos para personalizar mejor tu planificación nutricional.',
  },
  {
    feature_text: 'PATOLOGÍAS',
    feature_image: 'patologias.svg',
    feature_desciption: 'Contaréis con el mismo nutricionista que os pondrá las mismas recetas y lista de la compra.',
  },
  {
    feature_text: 'CONCILIACIÓN PAREJAS',
    feature_image: 'conciliacion-parejas.svg',
    feature_desciption: 'Contaréis con el mismo nutricionista que os pondrá las mismas recetas y lista de la compra.',
  },
  {
    feature_text: 'SUPLEMENTACIÓN',
    feature_image: 'suplementacion.svg',
    feature_desciption: 'Planificación de preentrenos, avituallamientos y postentrenos.',
  },
];
