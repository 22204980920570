
import {
  computed,
  defineComponent, reactive,
  ref,
} from 'vue';
import ShellInput from './ShellInput.vue';

export default defineComponent({
  emits: ['click', 'update:modelValue', 'blur', 'enter'],
  components: { ShellInput },
  props: {
    modelValue: String,
    placeholder: String,
    error: {
      type: String,
      default: '',
    },
    message: String,
  },
  setup(props, { emit }) {
    const passwordStatus = ref('closed');
    const type = ref('password');

    const throwEvent = (event: Event) => {
      const text = (event.target as HTMLInputElement).value;
      emit('update:modelValue', text);
    };

    const showPlaceholder = computed(() => props.modelValue !== '');

    const showPassword = () => {
      passwordStatus.value = 'opened';
      type.value = 'text';
    };

    const hidePassword = () => {
      passwordStatus.value = 'closed';
      type.value = 'password';
    };

    const isActive = reactive({
      value: false,
    });
    return {
      passwordStatus,
      type,
      showPlaceholder,
      isActive,

      showPassword,
      hidePassword,
      throwEvent,
    };
  },
});
