import User from '../models/User';

class NavigationRedirector {
  userHasPayedSubscription(user: User): boolean {
    if (user.navigation.screen === 'onboarding' && !user.navigation.onboarding.payment) {
      return true;
    }

    if (user.navigation.screen === 'onboarding' || user.navigation.screen === 'payment') {
      return false;
    }

    if (user.navigation.screen === 'planification' || user.navigation.screen === 'thankyou') {
      return true;
    }

    return false;
  }
}

export default new NavigationRedirector();
