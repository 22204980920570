import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-af235dd4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "price-subscription-title" }
const _hoisted_2 = { class: "plan-cards" }
const _hoisted_3 = { class: "packs-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_PlanCard = _resolveComponent("PlanCard")!
  const _component_FeatureCards = _resolveComponent("FeatureCards")!
  const _component_Nutritionists = _resolveComponent("Nutritionists")!
  const _component_UniqueAsYou = _resolveComponent("UniqueAsYou")!
  const _component_UniqueAsYouMobile = _resolveComponent("UniqueAsYouMobile")!
  const _component_Ambassadors = _resolveComponent("Ambassadors")!
  const _component_IndyaInFacts = _resolveComponent("IndyaInFacts")!
  const _component_GoogleReviews = _resolveComponent("GoogleReviews")!
  const _component_Awakers = _resolveComponent("Awakers")!
  const _component_AwakersMobile = _resolveComponent("AwakersMobile")!
  const _component_Collaborations = _resolveComponent("Collaborations")!
  const _component_CollaborationsMobile = _resolveComponent("CollaborationsMobile")!
  const _component_RedirectCta = _resolveComponent("RedirectCta")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "select-plan",
      style: _normalizeStyle({
        backgroundImage:
        'linear-gradient(#00000090, #00000090),' +
        'url(' + require('@/assets/images/header.jpg') + '),' +
        'linear-gradient(#000000, #000000)'
      })
    }, [
      _createVNode(_component_Header, { class: "main-header" }),
      _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.PRECIOS_SUSCRIPCION), 1),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.planCards[0].subscriptions, (subscription, index) => {
          return (_openBlock(), _createBlock(_component_PlanCard, {
            key: subscription.id,
            variant: _ctx.variants[index % _ctx.variants.length],
            planCard: _ctx.planCards[0],
            promo: subscription.newPromo,
            subscription: subscription,
            price: subscription.price,
            priceMonth: subscription.priceMonth,
            basePrice: subscription.basePrice,
            onSelected: _ctx.planSelected
          }, null, 8, ["variant", "planCard", "promo", "subscription", "price", "priceMonth", "basePrice", "onSelected"]))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", null, _toDisplayString(_ctx.TEXTO_PACKS), 1)
      ]),
      _createVNode(_component_FeatureCards)
    ], 4),
    _createVNode(_component_Nutritionists),
    (_ctx.resolution > 820)
      ? (_openBlock(), _createBlock(_component_UniqueAsYou, { key: 0 }))
      : (_openBlock(), _createBlock(_component_UniqueAsYouMobile, { key: 1 })),
    _createVNode(_component_Ambassadors),
    (_ctx.resolution > 820)
      ? (_openBlock(), _createBlock(_component_IndyaInFacts, {
          key: 2,
          image: "Numbers.jpg"
        }))
      : (_openBlock(), _createBlock(_component_IndyaInFacts, {
          key: 3,
          image: "Numbers_mobile.jpg"
        })),
    _createVNode(_component_GoogleReviews),
    (_ctx.resolution > 419)
      ? (_openBlock(), _createBlock(_component_Awakers, { key: 4 }))
      : (_openBlock(), _createBlock(_component_AwakersMobile, { key: 5 })),
    (_ctx.resolution > 419)
      ? (_openBlock(), _createBlock(_component_Collaborations, { key: 6 }))
      : (_openBlock(), _createBlock(_component_CollaborationsMobile, { key: 7 })),
    _createVNode(_component_RedirectCta)
  ]))
}