
import {
  computed,
  defineComponent,
  ref,
} from 'vue';
import Button from '@/components/atoms/button.vue';
import {
  validateEmpty,
  validateEmail,
  validateDNI,
} from '@/domain/services/validationHelper';
import EmailInput from '../molecules/EmailInput.vue';

export default defineComponent({
  components: {
    Button,
    EmailInput,
  },
  emits: ['reset'],
  setup(_, { emit }) {
    const email = ref('');

    const formValidated = computed(() => {
      if (validateEmpty(email.value)) {
        return false;
      }

      if (!validateEmail(email.value) && !validateDNI(email.value)) {
        return false;
      }

      return true;
    });

    const throwEvent = () => {
      if (!formValidated.value) {
        return;
      }

      emit('reset', email.value);
    };

    return {
      email,
      formValidated,

      throwEvent,
    };
  },
});
