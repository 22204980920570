import PlanCardElement from '@/domain/models/PlanCardElement';

export default class Subscription {
  $id: string;

  $name: string;

  $planType: string;

  $price: number;

  $priceMonth: string;

  $basePrice: number;

  $couponDuration: number;

  $couponAmountDiscount: number;

  $paymentConditions: string;

  $conditions: string;

  $promo: string;

  $paymentProductId: string;

  $elementsFront: null|Array<PlanCardElement>;

  $elementsIncludedFront: null|Array<string>;

  $elementsNotIncludedFront: null|Array<string>;

  $elementsFeatured: null|Array<string>;

  $couponType: null|string;

  $farePricePerMonthCoupon: null|number;

  $newPromo: null|string;

  $activePlan: boolean;

  $period: null|string;

  $couponNewPromo: null|string;

  constructor(
    id: string,
    name: string,
    planType: string,
    price: number,
    priceMonth: string,
    basePrice: number,
    couponDuration: number,
    couponAmountDiscount: number,
    paymentConditions: string,
    conditions: string,
    promo: string,
    paymentProductId: string,
    elementsFront: Array<PlanCardElement>,
    elementsFeatured: Array<string>,
    elementsNotIncludedFront: Array<string>,
    elementsIncludedFront: Array<string>,
    activePlan: boolean,
    couponType: null|string,
    farePricePerMonthCoupon: null|number,
    newPromo: null|string,
    period: null|string,
    couponNewPromo: null|string,
  ) {
    this.$id = id;
    this.$name = name;
    this.$planType = planType;
    this.$price = price;
    this.$priceMonth = priceMonth;
    this.$basePrice = basePrice;
    this.$couponDuration = couponDuration;
    this.$couponAmountDiscount = couponAmountDiscount;
    this.$paymentConditions = paymentConditions;
    this.$conditions = conditions;
    this.$promo = promo;
    this.$paymentProductId = paymentProductId;
    this.$elementsFront = elementsFront;
    this.$elementsIncludedFront = elementsIncludedFront;
    this.$elementsNotIncludedFront = elementsNotIncludedFront;
    this.$elementsFeatured = elementsFeatured;
    this.$couponType = couponType;
    this.$farePricePerMonthCoupon = farePricePerMonthCoupon;
    this.$newPromo = newPromo;
    this.$activePlan = activePlan;
    this.$period = period;
    this.$couponNewPromo = couponNewPromo;
  }

  get id(): string {
    return this.$id;
  }

  get name(): string {
    return this.$name;
  }

  get planType(): string {
    return this.$planType;
  }

  get price(): number {
    return this.$price;
  }

  get priceMonth(): string {
    return this.$priceMonth;
  }

  get basePrice(): number {
    return this.$basePrice;
  }

  get couponDuration(): number {
    return this.$couponDuration;
  }

  get couponAmountDiscount(): number {
    return this.$couponAmountDiscount;
  }

  get paymentConditions(): string {
    return this.$paymentConditions;
  }

  get conditions(): string {
    return this.$conditions;
  }

  get promo(): string {
    return this.$promo;
  }

  get paymentProductId(): string {
    return this.$paymentProductId;
  }

  get elementsFront(): null|Array<PlanCardElement> {
    return this.$elementsFront;
  }

  get elementsIncludedFront(): null|Array<string> {
    return this.$elementsIncludedFront;
  }

  get elementsNotIncludedFront(): null|Array<string> {
    return this.$elementsNotIncludedFront;
  }

  get elementsFeatured(): null|Array<string> {
    return this.$elementsFeatured;
  }

  get couponType(): string | null {
    return this.$couponType;
  }

  get farePricePerMonthCoupon(): number | null {
    return this.$farePricePerMonthCoupon;
  }

  get newPromo(): string | null {
    return this.$newPromo;
  }

  get activePlan(): boolean {
    return this.$activePlan;
  }

  get period(): string | null {
    return this.$period;
  }

  get couponNewPromo(): string | null {
    return this.$couponNewPromo;
  }
}
