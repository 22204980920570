import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15d3d3eb"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["placeholder", "value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShellInput = _resolveComponent("ShellInput")!

  return (_openBlock(), _createBlock(_component_ShellInput, {
    placeholder: _ctx.placeholder,
    showPlaceholder: _ctx.showPlaceholder,
    isActive: _ctx.isActive.value,
    error: _ctx.error,
    message: _ctx.message,
    onFocusin: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isActive.value = true)),
    onFocusout: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isActive.value = false))
  }, {
    default: _withCtx(() => [
      (_ctx.isActive.value)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: require('@/assets/images/email-input-icon-active.svg'),
            alt: "email"
          }, null, 8, _hoisted_1))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: require('@/assets/images/email-input-icon.svg'),
            alt: "email"
          }, null, 8, _hoisted_2)),
      _createElementVNode("input", {
        type: "email",
        placeholder: _ctx.placeholder,
        value: _ctx.modelValue,
        disabled: _ctx.disabled,
        onKeydown: _cache[0] || (_cache[0] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('enter')), ["prevent"]), ["enter"])),
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.throwEvent($event))),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('blur', this)))
      }, null, 40, _hoisted_3)
    ]),
    _: 1
  }, 8, ["placeholder", "showPlaceholder", "isActive", "error", "message"]))
}