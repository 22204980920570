import { openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5284abe6"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  width: "100%",
  height: "680px",
  src: "https://www.youtube.com/embed/J5NiVcE7pNk?autoplay=0&mute=1&loop=1&authide=1&disablekb=1&rel=0&playlist=J5NiVcE7pNk&showinfo=0",
  title: "YouTube video player",
  frameborder: "0",
  allow: "accelerometer;\n                    autoplay;\n                    clipboard-write; encrypted-media; gyroscope; picture-in-picture",
  allowfullscreen: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("iframe", _hoisted_1, "\n    "))
}