
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
import store from '@/store';

export default defineComponent({
  props: {
    image: {
      type: String,
    },
    basePrice: {
      type: Number,
      required: true,
    },
    priceMonth: {
      type: Number,
      required: true,
    },
    isNewPrice: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  emits: ['selected'],
  setup() {
    const route = useRoute();
    const discountName = () => {
      if (route.query.utm_campaign === 'capitan') {
        return 'Programa Capitán';
      }
      if (!route.query.utm_id) {
        return store.getters.coupon;
      }
      return route.query.utm_id;
    };

    return {
      discountName,
    };
  },
});
