import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "time-out-accept" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "accept-content" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 2,
  id: "accept-content-text"
}
const _hoisted_7 = {
  key: 3,
  id: "accept-content-text"
}
const _hoisted_8 = { style: {"text-align":"center"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListElement = _resolveComponent("ListElement")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      id: "time-out-exit-button",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeModal')))
    }, [
      _createElementVNode("img", {
        src: require('@/assets/images/salir.png'),
        alt: "step1"
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.type.amateur)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_4, "¡Fantastico!"))
        : (_ctx.type.amapro)
          ? (_openBlock(), _createElementBlock("h2", _hoisted_5, "Esto es otra liga"))
          : _createCommentVNode("", true),
      (_ctx.type.amateur)
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, " A partir del día " + _toDisplayString(_ctx.currentSubscription.nextPayDate) + " podrás seguir con estas funcionalidades: ", 1))
        : (_ctx.type.amapro)
          ? (_openBlock(), _createElementBlock("p", _hoisted_7, " Ya puedes accerder a: "))
          : _createCommentVNode("", true),
      _createElementVNode("ul", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elementsFeatured, (element) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "elements-step3",
            key: element
          }, [
            _createVNode(_component_ListElement, {
              element: element,
              type: _ctx.image
            }, null, 8, ["element", "type"])
          ]))
        }), 128))
      ]),
      _createVNode(_component_Button, {
        variant: _ctx.buttonVariant,
        onClick: _ctx.refreshProfile
      }, {
        default: _withCtx(() => [
          _createTextVNode("¡Perfecto!")
        ]),
        _: 1
      }, 8, ["variant", "onClick"])
    ])
  ]))
}