
import {
  computed,
  defineComponent, onMounted,
  reactive,
  ref,
} from 'vue';
import Header from '@/components/molecules/Header.vue';
import { useStore } from 'vuex';
import PersonalData from '@/components/molecules/PersonalData.vue';
import PaymentMethod from '@/components/organisms/PaymentMethod.vue';
import Payments from '@/components/organisms/Payments.vue';
import Modal from '@/components/atoms/Modal.vue';
import Button from '@/components/atoms/button.vue';
import CancelStep2 from '@/components/organisms/CancelStep2.vue';
import ProfileResubscription from '@/components/molecules/ProfileResubscription.vue';
import CancelMotive from '@/domain/models/CancelMotive';
import SubscriptionCard from '@/domain/models/SubscriptionCard';
import { useLoading } from 'vue-loading-overlay';
import NavigationRedirector from '@/domain/services/NavigationRedirector';
import { useRouter } from 'vue-router';
import wasPaidViaApplePay from '@/domain/services/applePayHelper';
import useAmplitude from '@//composables/useAmplitude';
import TimeOut from '@/components/organisms/TimeOut.vue';
import ActivatePlan from '@/domain/models/ActivatePlan';
import UserImage from '@/components/atoms/UserImage.vue';

export default defineComponent({
  name: 'HomeView',
  components: {
    UserImage,
    ProfileResubscription,
    TimeOut,
    Header,
    PersonalData,
    PaymentMethod,
    Payments,
    Modal,
    Button,
    CancelStep2,
  },
  setup() {
    const loading = useLoading({ backgroundColor: 'black', color: 'white' });
    const cancel = reactive({
      Step1: false,
      Step2: false,
      Step3: false,
      Step4: false,
    });
    const reenableSubscriptionModals = reactive({
      Step1: false,
      Step2: false,
    });
    const profileRetryPaymentStatus = reactive({
      successful: false,
      failed: false,
    });
    const paymentMethodError = ref('');
    const motives = ref();
    const selectedMotive = ref(-1);
    const subscriptionEndDate = ref('');
    const router = useRouter();
    const store = useStore();
    const timeOutPlan = ref();
    const user = computed(() => store.getters.user);
    const subscriptionCard = ref(null as SubscriptionCard|null);
    const currentSubscription = ref(store.getters.currentSubscription);
    const appleInApp = ref(false);
    const showPaymentReview = computed(() => user.value?.subscriptionStatus === 'expired');
    onMounted(() => {
      useAmplitude()?.setUserId(user.value.$clientId);
      const userProperties = {
        client_fare: user.value.subscription,
      };
      useAmplitude()?.setUserProperties(userProperties);
      useAmplitude()?.logEvent('Profile_screen');
    });
    const loader = loading.show();

    const toBeSettled = [
      store.dispatch('subscriptionData')
        .then((res) => { subscriptionCard.value = res; }),
      store.dispatch('userBasicInfo'),
    ];

    let defaultPaymentId = null as string|null;

    toBeSettled.push(
      store.dispatch('currentSubscription')
        .then((res) => { currentSubscription.value = res; }),
    );

    Promise.allSettled(toBeSettled)
      .finally(() => {
        if (!NavigationRedirector.userHasPayedSubscription(store.getters.user)) {
          router.push({ name: 'select-plan' });
        }
        loader.hide();
      });

    store.dispatch('cancelMotives')
      .then((res: CancelMotive[]) => {
        motives.value = res;
      });

    const save = (card: { token: { id: string } }, callback: any) => {
      const loader = loading.show();
      store.dispatch('createCard', card.token.id)
        .then(() => {
          store.dispatch('subscriptionData')
            .then((res) => {
              subscriptionCard.value = res;
            })
            .finally(() => {
              loader.hide();
            });
          paymentMethodError.value = '';
          callback({ code: '200', title: 'Éxito', text: 'Datos actualizados correctamente' });
        })
        .catch((res) => {
          paymentMethodError.value = res.response.data.data.message;
          callback({ code: '500', title: 'Error', text: res.response.data.data.message });
        })
        .finally(() => {
          loader.hide();
        });
    };

    function isTimeOut(cancelWithOutTimeOut: boolean) {
      if (currentSubscription.value.lastPayType === 'MANTENIMIENTO') {
        return false;
      }
      if (currentSubscription.value.nextPayType === 'MANTENIMIENTO') {
        return false;
      }
      if (cancelWithOutTimeOut) {
        return false;
      }
      if (currentSubscription.value.isPack) {
        return false;
      }
      return true;
    }

    function timeOut() {
      const loader = loading.show();
      toBeSettled.push(
        store.dispatch('getPlanTimeOut')
          .then((res) => {
            timeOutPlan.value = res;
            cancel.Step2 = false;
            cancel.Step3 = true;
            loader.hide();
          }),
      );
    }

    const cancelSubscription = (cancelWithOutTimeOut: boolean) => {
      if (isTimeOut(cancelWithOutTimeOut)) {
        timeOut();
      } else {
        const loader = loading.show();
        store.dispatch('cancelSubscription', motives.value[selectedMotive.value - 1].motive)
          .then(() => {
            store.dispatch('currentSubscription')
              .then((res) => {
                currentSubscription.value = res;
                store.dispatch('userBasicInfo')
                  .then(() => {
                    cancel.Step2 = false;
                    cancel.Step3 = false;
                    cancel.Step4 = true;
                    useAmplitude()?.logEvent('Downgrade_screen');
                    (window as any)?.dataLayer.push({
                      event: 'Downgrade_screen',
                    });
                    toBeSettled.push(
                      store.dispatch('currentSubscription')
                        .then((res) => { currentSubscription.value = res; }),
                    );
                    loader.hide();
                  });
              });
          });
      }
    };

    const askReenableSubscription = () => {
      reenableSubscriptionModals.Step1 = true;
    };

    const reenableSubscription = () => {
      const loader = loading.show();
      reenableSubscriptionModals.Step1 = false;
      store.dispatch('reactivateSubscription')
        .then(() => {
          store.dispatch('userBasicInfo')
            .then(() => {
              store.dispatch('currentSubscription')
                .then((res) => {
                  currentSubscription.value = res;
                  reenableSubscriptionModals.Step2 = true;
                  loader.hide();
                });
            });
        });
    };

    function logResubscriptionConfirmed() {
      useAmplitude()?.logEvent('Profile_reactivate_confirmed_selected');
    }

    function confirmUpgradeWithSecurity(res: any, price: any) {
      const loader = loading.show();
      const stripe = new (window as any).Stripe(process.env.VUE_APP_STRIPE_KEY);
      const clientSecret = res.subscription.latest_invoice.payment_intent.client_secret;
      stripe.confirmCardPayment(clientSecret).then(() => {
        store.commit('unsetCurrentSubscription');
        loader.hide();
      });
    }

    function confirmUpgrade(res: any, price: any) {
      const loader = loading.show();
      store.commit('unsetCurrentSubscription');
      loader.hide();
    }

    const timeOutPlanPurchase = async (planSelected: string) => {
      const loader = loading.show();
      const price = timeOutPlan.value[0];
      await store.dispatch('getDefaultPayment')
        .then((res) => {
          defaultPaymentId = res.data.credit_card_id;
          const data = new ActivatePlan(
            defaultPaymentId,
            planSelected,
            null,
          );
          store.dispatch('activatePlan', data)
            .then((res) => {
              loader.hide();
              if (res.data.data.subscription.latest_invoice) {
                confirmUpgradeWithSecurity(res.data.data, price);
              } else {
                confirmUpgrade(res.data.data, price);
              }
            });
        });
    };

    if (NavigationRedirector.userHasPayedSubscription(store.getters.user)) {
      store.dispatch('getSubscription')
        .then((subscription) => {
          appleInApp.value = wasPaidViaApplePay(subscription);
        });
    }
    function profileRetryPayment() {
      const loader = loading.show();
      store.dispatch('retryPayment')
        .then(() => {
          useAmplitude()?.logEvent('Ficha_payment_retry_success_screen');
          store.dispatch('subscriptionData')
            .then((res) => { subscriptionCard.value = res; });
          loader.hide();
          profileRetryPaymentStatus.successful = true;
        }).catch((err) => {
          loader.hide();
          profileRetryPaymentStatus.failed = true;
        });
    }

    return {
      user,
      cancel,
      motives,
      selectedMotive,
      subscriptionEndDate,
      subscriptionCard,
      currentSubscription,
      paymentMethodError,
      appleInApp,
      reenableSubscriptionModals,
      showPaymentReview,
      profileRetryPaymentStatus,

      timeOutPlanPurchase,
      save,
      cancelSubscription,
      askReenableSubscription,
      reenableSubscription,
      logResubscriptionConfirmed,
      profileRetryPayment,
    };
  },
});
