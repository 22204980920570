
import { defineComponent } from 'vue';
import { TEXTO_FEATURES, TEXTOS_FEATURES } from '@/constants/texts.es';
import FeatureCard from '../molecules/FeatureCard.vue';

export default defineComponent({
  name: 'FeatureCards',
  components: {
    FeatureCard,
  },
  setup() {
    return {
      TEXTO_FEATURES,
      TEXTOS_FEATURES,
    };
  },
});

