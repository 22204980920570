

export default {
  data() {
    return {
      first: true,
      second: false,
      third: false,
      four: false,
      five: false,
      mobile: 'Plani.png',
    };
  },
};

