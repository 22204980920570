<template>
  <div class="step-done">
    <div class="title">¡Ya lo tienes!</div>
    <img :src="require('@/assets/images/done.png')" alt="Ya lo tienes" class="image">
    <div class="subtitle">Descárgate la app y empieza a mejorar tu rendimiento deportivo</div>
    <div class="stores">
      <a href="https://app.inutriciondeportiva.com/hnQrn5xfjob" target="_blank">
        <img :src="require('@/assets/images/AppleStore.png')" alt="Apple Store">
      </a>
      <a href="https://app.inutriciondeportiva.com/I3oICTufjob" target="_blank">
        <img :src="require('@/assets/images/GooglePlay.png')" alt="Google Play">
      </a>
    </div>
    <div class="QRCode">
      <img :src="require('@/assets/images/QRCode.svg')" alt="Código QR">
      <div>Escanea el QR y descárgate la app en tu móvil</div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, onMounted } from 'vue';
import { useStore } from 'vuex';
import useAmplitude from '@/composables/useAmplitude';
import PlanCard from '@/domain/models/PlanCard';
import Subscription from '@/domain/models/Subscription';

export default defineComponent({
  props: {
    selectedPlan: {
      type: PlanCard,
      required: true,
    },
    selectedSubscription: {
      type: Subscription,
      required: true,
    },
    appliedDiscount: {
      type: Boolean,
      default: false,
    },
    discountError: {
      type: Boolean,
      default: false,
    },
    discountAmount: {
      type: Number,
      default: 0,
    },
    discountCode: {
      type: String,
      default: '',
    },
    error: String,
  },
  setup() {
    const store = useStore();
    store.commit('forceUserHasPlan');
    onMounted(() => {
      useAmplitude()?.logEvent('Onboarding_suscription_successpha_screen');
      window.dataLayer.push({
        event: 'virtual_pageview_complete',
      });
    });
  },
});
</script>

<style lang="sass" scoped>
  .step-done
    @apply text-center text-white px-5
    img
      @apply inline
    .title
      @apply text-5xl font-bold mb-10
    .image
      @apply mb-10
    .subtitle
      @apply text-lg font-bold mb-7
    .stores
      @apply flex gap-5 justify-center mb-8
      a
        @apply max-w-[45%]
    .QRCode
      @apply text-xxs
      img
        @apply mb-3
</style>
