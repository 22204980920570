export function isForbiddenSubscription(currentId: string) {
  const oneMonthPlans = JSON.parse(process.env.VUE_APP_FORB_SUBS_UPGRD) as string[];
  return oneMonthPlans.indexOf(currentId) < 0;
}

export function getModalVariant(currentPlanName: string, newPlanName: string) {
  const variants = [
    ['amateur2amateur', 'turquoise', 'turquoise outlined'],
    ['amateur2amapro', 'turquoise', 'purple outlined'],
    ['amapro2amapro', 'purple', 'purple outlined'],
    ['amapro2amateur', 'purple', 'turquoise outlined'],
    ['rookie2amateur', 'purple', 'turquoise outlined'],
    ['rookie2amapro', 'purple', 'turquoise outlined'],
  ];
  let from = currentPlanName.toString().toLowerCase();
  if (currentPlanName.toString().toLowerCase() !== 'amateur' || currentPlanName.toString().toLowerCase() !== 'amapro') {
    from = 'rookie';
  }
  const to = newPlanName.toString().toLowerCase();
  const index = `${from}2${to}`;
  const [
    ,
    modalVariant,
    modalVariantOutlined,
  ] = variants.find((elem) => elem[0] === index) as string[];

  return [modalVariant, modalVariantOutlined];
}

export function getButtonVariant(currentPlanName: string) {
  const variants = [
    ['amapro', 'purple'],
    ['amateur', 'turquoise'],
    ['amapro 6 meses', 'purple'],
    ['amateur 6 meses', 'turquoise'],
    ['amapro 12 meses', 'purple'],
    ['amateur 12 meses', 'turquoise'],
  ];
  const index = currentPlanName.toLowerCase();
  const [
    ,
    modalVariant,
  ] = variants.find((elem) => elem[0] === index) as string[];

  return modalVariant;
}
