
import CurrentSubscription from '@/domain/models/CurrentSubscription';
import { defineComponent } from 'vue';
import User from '@/domain/models/User';
import SubscriptionCard from '@/domain/models/SubscriptionCard';
import PaymentRegular from './PaymentRegular.vue';
import PaymentApple from './PaymentApple.vue';

export default defineComponent({
  components: { PaymentRegular, PaymentApple },
  emits: ['reenableSubscription'],
  props: {
    currentSubscription: {
      type: CurrentSubscription,
      required: true,
    },
    appleInApp: Boolean,
    user: {
      type: User,
      required: true,
    },
    subscriptionCard: {
      type: SubscriptionCard,
    },
  },
});
