import ActivatePlan from '@/domain/models/ActivatePlan';
import axios from '@/plugins/axios';

const CREATE_CARD_URL = '/v2/credit-card/create';
const ACTIVATE_PLAN_URL = '/v2/stripe-payment/create-subscription';
const UPDATE_PLAN_URL = '/v2/stripe-payment/update-subscription';
const CANCEL_SUBSCRIPTION_URL = '/v2/subscription/cancel-subscription';
const DEFAULT_PAYMENT_METHOD = '/v2/credit-card/default-id';
const RETRY_PAYMENT_URL = '/v3/stripe-payment/pay-invoice';

export async function createCard(token: string) {
  return axios.post(CREATE_CARD_URL, {
    token,
  })
    .then((res) => res.data.data.id)
    .catch((error) => Promise.reject(error));
}

export async function activatePlan(data: ActivatePlan) {
  return axios.post(ACTIVATE_PLAN_URL, {
    paymentMethodId: data.paymentMethodId,
    productId: data.productId,
    promotionCodeId: data.promotionalCodeId,
  })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

export async function updatePlan(productId: string) {
  return axios.post(UPDATE_PLAN_URL, { productId })
    .then((res) => res.data.data)
    .catch((error) => Promise.reject(error));
}

export async function cancelSubscription(motives: string) {
  return axios.post(CANCEL_SUBSCRIPTION_URL, {
    cancellation_reason: motives,
  })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}

export async function getDefaultPaymentId() {
  return axios.get(DEFAULT_PAYMENT_METHOD)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}

export async function retryPayment() {
  return axios.get(RETRY_PAYMENT_URL)
    .then((res) => res.data)
    .catch((error) => Promise.reject(error));
}
