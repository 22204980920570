import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b8ed4e5e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input" }
const _hoisted_2 = { class: "message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["label", { shown: _ctx.showPlaceholder }])
    }, _toDisplayString(_ctx.placeholder), 3),
    _createElementVNode("div", {
      class: _normalizeClass(["group", 
    {
      error: _ctx.error != '',
      active: _ctx.isActive != false
    }])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["error-msg", { shown: _ctx.error !== '' }])
      }, " " + _toDisplayString(_ctx.error), 3),
      (_ctx.error === '')
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            onKeyup: _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.$emit('message', _ctx.message)), ["enter"])),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('message', _ctx.message)))
          }, " " + _toDisplayString(_ctx.message), 33))
        : _createCommentVNode("", true)
    ])
  ]))
}