export default class PromoCode {
  private $code: string;

  private $priceId: string;

  private $promotionCodeName: string;

  constructor(code: string, priceId: string, promotionCodeName?: any) {
    this.$code = code;
    this.$priceId = priceId;
    this.$promotionCodeName = promotionCodeName;
  }

  get code(): string {
    return this.$code;
  }

  get priceId(): string {
    return this.$priceId;
  }

  get promotionCodeName(): string {
    return this.$promotionCodeName;
  }
}
