import { Getters } from 'vuex-smart-module';
import { State } from '@/store/state';

export default class extends Getters<State> {
  get token() {
    return this.state.auth.token;
  }

  get plans() {
    return this.state.plans;
  }

  get planTimeOut() {
    return this.state.planTimeOut;
  }

  get currentSubscription() {
    return this.state.currentSubscription;
  }

  get loggedUser() {
    return this.getters.token !== null;
  }

  get user() {
    return this.state.user;
  }

  get coupon() {
    return this.state.coupon;
  }
}
