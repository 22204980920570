export default class ActivatePlan {
  private $paymentMethodId: string|null;

  private $productId: string;

  private $promotionalCodeId: string|null;

  constructor(paymentMethodId: string|null, productId: string, promotionalCodeId: string|null) {
    this.$paymentMethodId = paymentMethodId;
    this.$productId = productId;
    this.$promotionalCodeId = promotionalCodeId;
  }

  get paymentMethodId(): string|null {
    return this.$paymentMethodId;
  }

  get productId(): string {
    return this.$productId;
  }

  get promotionalCodeId(): string|null {
    return this.$promotionalCodeId;
  }
}
