
import {
  computed,
  defineComponent, reactive,
} from 'vue';
import ShellInput from './ShellInput.vue';

export default defineComponent({
  emits: ['click', 'update:modelValue', 'blur'],
  components: { ShellInput },
  props: {
    modelValue: String,
    type: String,
    placeholder: String,
    error: {
      type: String,
      default: '',
    },
    message: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const throwEvent = (event: Event) => {
      const text = (event.target as HTMLInputElement).value;
      emit('update:modelValue', text);
    };

    const showPlaceholder = computed(() => props.modelValue !== '');
    const isActive = reactive({
      value: false,
    });

    return {
      showPlaceholder,
      isActive,

      throwEvent,
    };
  },
});
