import { Navigation } from '../services/types';

export default class User {
  private $name: string|null = null;

  private $clientId: number|null = null;

  private $plan: string|null = null;

  private $subscription: string|null = null;

  private $navigation: Navigation;

  private $subscriptionStatus: string|null = null;

  private $email: string|null = null;

  private $userImage: string;

  constructor(
    name: string,
    email: string,
    navigation: Navigation,
    clientId: number,
    userImage: string,
    plan: string|null = null,
    subscription: string|null = null,
    subscriptionStatus: string|null = null,
  ) {
    this.$name = name;
    this.$email = email;
    this.$clientId = clientId;
    this.$plan = plan;
    this.$subscription = subscription;
    this.$subscriptionStatus = subscriptionStatus;
    this.$navigation = navigation;
    this.$userImage = userImage;
  }

  get name(): string|null {
    return this.$name;
  }

  get clientId(): number|null {
    return this.$clientId;
  }

  get plan(): string|null {
    return this.$plan;
  }

  get subscription(): string|null {
    return this.$subscription;
  }

  get subscriptionStatus(): string|null {
    return this.$subscriptionStatus;
  }

  get navigation(): Navigation {
    return this.$navigation;
  }

  set navigation(value: Navigation) {
    this.$navigation = value;
  }

  get email(): string|null {
    return this.$email;
  }

  get userImage(): string {
    return this.$userImage;
  }
}
