import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a3294ed"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "personal-data" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NameInput = _resolveComponent("NameInput")!
  const _component_EmailInput = _resolveComponent("EmailInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_NameInput, {
      placeholder: "Nombre",
      modelValue: _ctx.name,
      disabled: ""
    }, null, 8, ["modelValue"]),
    _createVNode(_component_EmailInput, {
      placeholder: "Email",
      modelValue: _ctx.email,
      disabled: ""
    }, null, 8, ["modelValue"])
  ]))
}