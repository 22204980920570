import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52cb6ef4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = ["type", "placeholder", "value"]
const _hoisted_4 = { class: "toggle" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShellInput = _resolveComponent("ShellInput")!

  return (_openBlock(), _createBlock(_component_ShellInput, {
    placeholder: _ctx.placeholder,
    showPlaceholder: _ctx.showPlaceholder,
    isActive: _ctx.isActive.value,
    error: _ctx.error,
    message: _ctx.message,
    onFocusin: _cache[5] || (_cache[5] = ($event: any) => (_ctx.isActive.value = true)),
    onFocusout: _cache[6] || (_cache[6] = ($event: any) => (_ctx.isActive.value = false))
  }, {
    default: _withCtx(() => [
      (_ctx.isActive.value)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: require('@/assets/images/password-input-icon-active.svg'),
            alt: _ctx.type
          }, null, 8, _hoisted_1))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            src: require('@/assets/images/password-input-icon.svg'),
            alt: _ctx.type
          }, null, 8, _hoisted_2)),
      _createElementVNode("input", {
        type: _ctx.type,
        placeholder: _ctx.placeholder,
        value: _ctx.modelValue,
        onKeyup: _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.$emit('enter')), ["enter"])),
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.throwEvent($event))),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('blur', this)))
      }, null, 40, _hoisted_3),
      _createElementVNode("span", _hoisted_4, [
        (_ctx.passwordStatus == 'closed')
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              class: "image",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showPassword()))
            }, [
              _createElementVNode("img", {
                src: require('@/assets/images/eye-closed.png'),
                alt: _ctx.type,
                width: "33"
              }, null, 8, _hoisted_5)
            ]))
          : (_openBlock(), _createElementBlock("button", {
              key: 1,
              type: "button",
              class: "image",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.hidePassword()))
            }, [
              _createElementVNode("img", {
                src: require('@/assets/images/eye-opened.svg'),
                alt: _ctx.type
              }, null, 8, _hoisted_6)
            ]))
      ])
    ]),
    _: 1
  }, 8, ["placeholder", "showPlaceholder", "isActive", "error", "message"]))
}