import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["placeholder", "value", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ShellInput = _resolveComponent("ShellInput")!

  return (_openBlock(), _createBlock(_component_ShellInput, {
    showPlaceholder: _ctx.showPlaceholder,
    placeholder: _ctx.placeholder,
    "is-active": _ctx.isActive.value,
    error: _ctx.error,
    message: _ctx.message,
    onFocusin: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isActive.value = true)),
    onFocusout: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isActive.value = false))
  }, {
    default: _withCtx(() => [
      _createElementVNode("input", {
        type: "text",
        placeholder: _ctx.placeholder,
        value: _ctx.modelValue,
        disabled: _ctx.disabled,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.throwEvent($event))),
        onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('blur', this)))
      }, null, 40, _hoisted_1)
    ]),
    _: 1
  }, 8, ["showPlaceholder", "placeholder", "is-active", "error", "message"]))
}