
import {
  defineComponent,
  nextTick,
  reactive,
  ref,
} from 'vue';
import {
  validateNameField,
} from '@/domain/services/validationHelper';
import { useLoading } from 'vue-loading-overlay';
import Modal from '@/components/atoms/Modal.vue';
import Button from '@/components/atoms/button.vue';
import useAmplitude from '@/composables/useAmplitude';
import NameInput from '../molecules/NameInput.vue';

export default defineComponent({
  props: {
    cardNumber: {
      type: String,
      required: true,
    },
    expire: {
      type: String,
      required: true,
    },
    error: String,
    appleInApp: Boolean,
  },
  components: {
    NameInput,
    Modal,
    Button,
  },
  emits: ['save'],
  setup(props, { emit }) {
    const loading = useLoading({ backgroundColor: 'black', color: 'white' });
    const card = reactive({
      token: '',
      name: '',
    });
    const stripeValidation = reactive({
      number: false,
      expire: false,
      cvv: false,
    });
    const showCardChanged = reactive({
      show: false,
      title: '',
      text: '',
    });
    const errorName = ref('');
    const opened = ref(false);
    const formValidated = ref(false);
    const link = process.env.VUE_APP_APPLE_PAY_LINK;
    const cardImage = reactive({
      value: 'password.png',
    });

    const validateForm = () => {
      formValidated.value = validateNameField(card.name) === ''
        && stripeValidation.number
        && stripeValidation.expire
        && stripeValidation.cvv;
    };

    const stripe = new (window as any).Stripe(process.env.VUE_APP_STRIPE_KEY);
    let cardNumber = null as any;
    let cardExpire = null as any;
    let cardCvv = null as any;

    const save = () => {
      const loader = loading.show();
      stripe.createToken(cardNumber, { name: card.name })
        .then(async (result: any) => {
          if (result.token) {
            card.token = result.token;

            const onSaveAction:
            {code: string, title: string, text: string} = await new Promise((resolve) => {
              emit('save', card, (respuesta: {code: string, title: string, text: string}) => resolve(respuesta));
            });

            showCardChanged.title = onSaveAction.title;
            showCardChanged.text = onSaveAction.text;
            opened.value = false;

            if (onSaveAction.code === '200') {
              useAmplitude()?.logEvent('Ficha_edit_data_fail_screen_success');
            }

            card.name = '';
            card.token = '';
            loader.hide();
            showCardChanged.show = true;
          }
        });
    };

    const openEditCard = () => {
      opened.value = true;

      const elements = stripe.elements({
        fonts: [{
          cssSrc: 'https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600',
        }],
      });
      const style = {
        base: {
          iconColor: '#c4f0ff',
          color: 'white',
          fontFamily: 'Montserrat, Helvetica',
          fontSize: '14px',
        },
        invalid: {
          color: 'white',
        },
        focus: {
          color: 'white',
        },
      };
      const classes = {
        base: 'border-b border-gray',
        invalid: 'border-b border-red',
        focus: 'border-b border-white',
      };
      cardNumber = elements.create('cardNumber', {
        classes,
        style,
        placeholder: 'Número de tarjeta',
      });
      cardExpire = elements.create('cardExpiry', {
        classes,
        style,
      });
      cardCvv = elements.create('cardCvc', {
        classes,
        style,
      });

      nextTick().then(() => {
        cardNumber.mount('#card-number');
        cardExpire.mount('#card-expire');
        cardCvv.mount('#card-cvv');
        cardNumber.on('change', (ev: any) => {
          cardImage.value = 'password-white.png';
          stripeValidation.number = ev.complete;
          validateForm();
        });
        cardExpire.on('change', (ev: any) => {
          cardImage.value = 'password.png';
          stripeValidation.expire = ev.complete;
          validateForm();
        });
        cardCvv.on('change', (ev: any) => {
          cardImage.value = 'password.png';
          stripeValidation.cvv = ev.complete;
          validateForm();
        });
      });
    };

    return {
      card,
      errorName,
      opened,
      formValidated,
      link,
      showCardChanged,
      cardImage,

      validateNameField,
      validateForm,
      openEditCard,
      save,
    };
  },
});
