<template>
  <div class="features-check-box-container">
    <p>Mostrar todas las funcionalidades</p>
    <label class="switch" for="features-check-box">
      <input type="checkbox" id="input-features-check-box"
             @click="switchSliderActivation" @change="$emit('showAllFeatures')">
      <span class="slider round"></span>
      <div v-if="switchSlider.active" class="switch-slider-active"></div>
      <div v-else ></div>
    </label>
  </div>
</template>

<script>
import { defineComponent, reactive } from 'vue';

export default defineComponent({
  emits: ['showAllFeatures'],
  setup() {
    const switchSlider = reactive({
      active: false,
    });

    function switchSliderActivation() {
      switchSlider.active = !switchSlider.active;
    }

    return {
      switchSlider,

      switchSliderActivation,
    };
  },
});
</script>

<style lang="sass">
.features-check-box-container
  @apply invisible
  @apply sm:visible sm:flex sm:flex-row sm:justify-around
  p
    @apply text-sm text-white
  .switch
    display: inline-block
    font-size: 20px
    height: 1em
    width: 2em
    background: #BDB9A6
    border-radius: 1em
    input#input-features-check-box
      position: absolute
      opacity: 0
      width: 2em
      height: 1em
      margin-left: 0
    div
      height: 1em
      width: 1em
      border-radius: 1em
      background: white
      box-shadow: 0 0.1em 0.3em rgba(0,0,0,0.3)
      -webkit-transition: all 300ms
      -moz-transition: all 300ms
      transition: all 300ms

    div.switch-slider-active
      @apply ml-[20px]

  .switch:hover
    input
      cursor: pointer

  .switch:checked
    > div
      transform: translate3d(100%, 0, 0)
</style>
