import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-021b89fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "payments" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaymentRegular = _resolveComponent("PaymentRegular")!
  const _component_PaymentApple = _resolveComponent("PaymentApple")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.appleInApp)
      ? (_openBlock(), _createBlock(_component_PaymentRegular, {
          key: 0,
          user: _ctx.user,
          "current-subscription": _ctx.currentSubscription,
          subscriptionCard: _ctx.subscriptionCard,
          onReenableSubscription: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('reenableSubscription')))
        }, null, 8, ["user", "current-subscription", "subscriptionCard"]))
      : (_openBlock(), _createBlock(_component_PaymentApple, {
          key: 1,
          user: _ctx.user,
          "current-subscription": _ctx.currentSubscription,
          subscriptionCard: _ctx.subscriptionCard
        }, null, 8, ["user", "current-subscription", "subscriptionCard"]))
  ]))
}