import PlanCardModel from '@/domain/models/PlanCard';
import Subscription from '@/domain/models/Subscription';
import PlanCardElement from '@/domain/models/PlanCardElement';

export default new PlanCardModel(
  '',
  process.env.VUE_APP_SPECIAL_PLAN_NAME,
  process.env.VUE_APP_SPECIAL_PLAN_TITLE,
  [new Subscription(
    '',
    'PRO',
    '',
    process.env.VUE_APP_SPECIAL_PLAN_PRICE,
    '',
    0,
    0,
    0,
    '',
    '',
    '',
    '',
    [
      new PlanCardElement('Servicio Amapro completo', 'y además:'),
      new PlanCardElement('Llamada o videollamada semanal', 'para recibir feedback y realizar los reajustes necesarios en la planificación'),
      new PlanCardElement('Valoración de marcadores', 'a través de la sincronización de dispositivos de monitorización'),
      new PlanCardElement('Coordinación con staff técnico', 'para alinear el trabajo y tener una visión integral del rendimiento'),
    ],
    [
      'Servicio Amapro completo y además:',
      'Llamada o videollamada semanal para recibir feedback y realizar los reajustes necesarios en la planificación',
      'Valoración de marcadores a través de la sincronización de dispositivos de monitorización',
      'Coordinación con staff técnico para alinear el trabajo y tener una visión integral del rendimiento',
    ],
    [
      'Servicio Amapro completo y además:',
      'Llamada o videollamada semanal para recibir feedback y realizar los reajustes necesarios en la planificación',
      'Valoración de marcadores a través de la sincronización de dispositivos de monitorización',
      'Coordinación con staff técnico para alinear el trabajo y tener una visión integral del rendimiento',
    ],
    [
      'Servicio Amapro completo y además:',
      'Llamada o videollamada semanal para recibir feedback y realizar los reajustes necesarios en la planificación',
      'Valoración de marcadores a través de la sincronización de dispositivos de monitorización',
      'Coordinación con staff técnico para alinear el trabajo y tener una visión integral del rendimiento',
    ],
    false,
    null,
    null,
    null,
    null,
    null,
  )],
);
