
import {
  computed, defineComponent, onMounted, reactive,
} from 'vue';
import Button from '@/components/atoms/button.vue';
import ListElement from '@/components/atoms/ListElement.vue';
import useAmplitude from '@/composables/useAmplitude';

export default defineComponent({
  components: { ListElement, Button },
  emits: ['closeModal'],
  props: {
    currentSubscription: {
      type: [],
      required: true,
    },
    elementsFeatured: {
      type: [],
      required: true,
    },
    buttonVariant: {
      type: [],
      required: true,
    },
  },
  setup(props) {
    const type = reactive({
      amateur: false,
      amapro: false,
    });

    onMounted(() => {
      useAmplitude()?.logEvent('Wellcome_screen');
      (window as any)?.dataLayer.push({
        event: 'Wellcome_screen',
      });
    });

    if (props.buttonVariant === 'purple') {
      type.amapro = true;
    }

    if (props.buttonVariant === 'turquoise') {
      type.amateur = true;
    }

    function refreshProfile() {
      window.location.href = '/profile';
    }

    const image = (computed(() => (props.buttonVariant === 'purple' ? 'amaproUpgrade' : 'finally')));

    return {
      type,
      image,

      refreshProfile,
    };
  },
});
