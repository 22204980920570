import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "feature" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "feature-text" }
const _hoisted_4 = { class: "feature-description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "feature-image",
      src: require('@/assets/images/features/'+ _ctx.image),
      alt: "feature"
    }, null, 8, _hoisted_2),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.text), 1),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.description), 1)
  ]))
}