
import { defineComponent } from 'vue';
import Button from '@/components/atoms/button.vue';
import useAmplitude from '@/composables/useAmplitude';

export default defineComponent({
  components: {
    Button,
  },
  emits: ['profileRetryPayment'],
  setup(props, { emit }) {
    function retryPayment() {
      useAmplitude()?.logEvent('Ficha_payment_retry_selected');
      emit('profileRetryPayment');
    }
    return {
      retryPayment,
    };
  },
});
