import { ManualUpdate, RawCurrentSubscription } from '../services/types';

/* eslint camelcase: ["error", {allow: [".*"]}] */
export default class CurrentSubscription {
  $lastPayType: string;

  $lastPayDate: string;

  $lastPayFinaleValue: string;

  $lastPayOriginalValue: string;

  $nextPayType: string;

  $nextPayDate: string;

  $nextPayOriginalValue: string;

  $nextPayFinaleValue: string;

  $productId: string;

  $isPack: boolean;

  $manualUpdateTitle: string|null;

  $manualUpdateText: string|null;

  $lastPayDiscounts: Array<string>|null;

  $nextPayDiscounts: Array<string>|null;

  constructor(
    {
      last_pay_type,
      last_pay_date,
      last_pay_finale_value,
      last_pay_original_value,
      next_pay_type,
      next_pay_date,
      next_pay_original_value,
      next_pay_finale_value,
      stripe_product_id,
      last_pay_discounts,
      next_pay_discounts,
    }: RawCurrentSubscription,
    manualUpdate: ManualUpdate|null,
    isPack: boolean,
  ) {
    this.$lastPayType = last_pay_type;
    this.$lastPayDate = last_pay_date;
    this.$lastPayFinaleValue = last_pay_finale_value;
    this.$lastPayOriginalValue = last_pay_original_value;
    this.$nextPayType = next_pay_type;
    this.$nextPayDate = next_pay_date;
    this.$nextPayOriginalValue = next_pay_original_value;
    this.$nextPayFinaleValue = next_pay_finale_value;
    this.$productId = stripe_product_id;
    this.$manualUpdateTitle = manualUpdate?.title || null;
    this.$manualUpdateText = manualUpdate?.text || null;
    this.$isPack = isPack;
    this.$lastPayDiscounts = last_pay_discounts || null;
    this.$nextPayDiscounts = next_pay_discounts || null;
  }

  get lastPayType(): string {
    return this.$lastPayType;
  }

  get lastPayDate(): string {
    return this.$lastPayDate;
  }

  get lastPayFinaleValue(): string {
    return this.$lastPayFinaleValue;
  }

  get lastPayOriginalValue(): string {
    return this.$lastPayOriginalValue;
  }

  get nextPayType(): string {
    return this.$nextPayType;
  }

  get nextPayDate(): string {
    return this.$nextPayDate;
  }

  get nextPayOriginalValue(): string {
    return this.$nextPayOriginalValue;
  }

  get nextPayFinaleValue(): string {
    return this.$nextPayFinaleValue;
  }

  get productId(): string {
    return this.$productId;
  }

  get manualUpdateTitle(): string|null {
    return this.$manualUpdateTitle;
  }

  get manualUpdateText(): string|null {
    return this.$manualUpdateText;
  }

  get isPack(): boolean {
    return this.$isPack;
  }

  get hasNextPayments(): boolean {
    return this.$nextPayType !== null;
  }

  get lastPayDiscounts(): Array<string> | null {
    return this.$lastPayDiscounts;
  }

  get nextPayDiscounts(): Array<string> | null {
    return this.$nextPayDiscounts;
  }
}
