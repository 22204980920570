/* eslint camelcase: ["error", {allow: [".*"]}] */
export default class SubscriptionCard {
  $cardNumber: string;

  $expiration: string;

  constructor({ card_number, expiration }: { card_number: string, expiration: string }) {
    this.$cardNumber = card_number;
    this.$expiration = expiration;
  }

  get cardNumber(): string {
    return this.$cardNumber;
  }

  get expiration(): string {
    return this.$expiration;
  }
}
