import axios from '@/plugins/axios';
import LoginUserForm from '@/domain/models/LoginUserForm';
import RegisterUserForm from '@/domain/models/RegisterUserForm';
import BasicAuth from '@/domain/models/BasicAuth';
import { loginData } from '@/domain/services/types';

const URL_LOGIN = '/v2/login';
const URL_REGISTER = '/v2/onboarding/user-register';

export async function login(formData: LoginUserForm) {
  const payload = btoa(`${formData.email}:${formData.password}`);
  return axios
    .post(URL_LOGIN, null, {
      headers: {
        Authorization: `Basic ${payload}`,
      },
    })
    .then((res: loginData) => new BasicAuth(formData.email, res.data.data.authentication_token))
    .catch((error) => Promise.reject(error));
}

export async function register(formData: RegisterUserForm) {
  return axios
    .post(URL_REGISTER, {
      firstname: formData.name,
      email: formData.email,
      password: formData.password,
    })
    .then((res) => res)
    .catch((error) => Promise.reject(error.response.data.data));
}
