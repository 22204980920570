

export default {
  name: 'ToggleDiv',
  data() {
    return {
      isShow: true,
    };
  },

  props: {
    msg: String,
  },
};

