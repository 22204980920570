import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-bb4c1200"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "back-steps" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_StepsTag = _resolveComponent("StepsTag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.step === _ctx.Steps.Done)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: { name: 'profile' }
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: require('@/assets/images/left.svg'),
              alt: "back"
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_StepsTag, {
      elements: _ctx.elements,
      "selected-index": _ctx.selectedIndex
    }, null, 8, ["elements", "selected-index"])
  ]))
}