import CurrentSubscription from '@/domain/models/CurrentSubscription';
import PlanCard from '@/domain/models/PlanCard';
import User from '@/domain/models/User';
import { MonthlyReportData } from '@/domain/services/types';

export class State {
  auth = {
    token: localStorage.getItem(process.env.VUE_APP_ACCESS_TOKEN) || null,
  };

  plans = [] as PlanCard[];

  planTimeOut = [] as PlanCard[];

  currentSubscription = null as CurrentSubscription | null;

  user = null as User|null;

  coupon = null as string|null;

  monthlyReport = null as MonthlyReportData | null;
}

export const getDefaultState = () => new State();
