import { MonthlyReportData, MonthlyReportResponse } from '@/domain/services/types';
import axios from '@/plugins/axios';

const REPORT_URL = '/v3/athlete-monthly-report/?uuid={uuid}';

export async function fetchMonthlyReport(uuid: string): Promise<MonthlyReportData> {
  return axios.get<MonthlyReportResponse>(REPORT_URL.replace('{uuid}', uuid))
    .then((res) => res.data.data)
    .catch((error) => Promise.reject(error));
}

export async function dummy() {
  console.log();
}
