
import CurrentSubscription from '@/domain/models/CurrentSubscription';
import User from '@/domain/models/User';
import { computed, defineComponent } from 'vue';
import reenableSubscriptionHelper from '@/domain/services/reenableSubscriptionHelper';
import SubscriptionCard from '@/domain/models/SubscriptionCard';
import Button from '../atoms/button.vue';

export default defineComponent({
  components: { Button },
  props: {
    currentSubscription: {
      type: CurrentSubscription,
      required: true,
    },
    user: {
      type: User,
      required: true,
    },
    subscriptionCard: {
      type: SubscriptionCard,
      required: true,
    },
  },
  setup(props) {
    const canReenableSubscription = computed(() => {
      if (!props?.subscriptionCard.cardNumber) {
        return true;
      }
      if (props.user) {
        return false;
      }
      return reenableSubscriptionHelper(props.user);
    });

    const goTo = () => {
      window.open(process.env.VUE_APP_APPLE_PAY_LINK, '_blank');
    };

    return {
      canReenableSubscription,

      goTo,
    };
  },
});
