<template>
  <div class="collaborations">
    <div class="margin-block"></div>
    <h3>COLABORACIONES</h3>
    <div class="collaborations-logos">
      <div class="collaborations-line-1">
        <swiper autoplay="true">
          <swiper-slide>
            <img
                :src="require('@/assets/images/TEO-MARTIN.svg')"
                alt="titulo_de_seccion">
          </swiper-slide>
          <swiper-slide>
            <img
                :src="require('@/assets/images/QH.svg')"
                alt="titulo_de_seccion">
          </swiper-slide>
          <swiper-slide>
            <img
                :src="require('@/assets/images/VALENCIA-CF.svg')"
                alt="titulo_de_seccion">
          </swiper-slide>
          <swiper-slide>
            <img
                :src="require('@/assets/images/GLAMOUR-SPORT.svg')"
                alt="titulo_de_seccion">
          </swiper-slide>
          <swiper-slide>
            <img
                :src="require('@/assets/images/TRICV.svg')"
                alt="titulo_de_seccion">
          </swiper-slide>
          <swiper-slide>
            <img
                :src="require('@/assets/images/RFE-CICLISMO.svg')"
                alt="titulo_de_seccion">
          </swiper-slide>
          <swiper-slide>
            <img
                :src="require('@/assets/images/JAGUAR.svg')"
                alt="titulo_de_seccion">
          </swiper-slide>
          <swiper-slide >
            <img
                :src="require('@/assets/images/REEBOK.svg')"
                alt="titulo_de_seccion">
          </swiper-slide>
          <swiper-slide>
            <img
                :src="require('@/assets/images/WATTSLAB.svg')"
                alt="titulo_de_seccion">
          </swiper-slide>
          <swiper-slide style="margin-left: 4%">
            <img
                :src="require('@/assets/images/REDBULL.svg')"
                alt="titulo_de_seccion">
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';

// eslint-disable-next-line import/extensions
import 'swiper/scss';
// eslint-disable-next-line import/extensions
import 'swiper/scss/navigation';
// eslint-disable-next-line import/extensions
import 'swiper/scss/pagination';

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Swiper,
    // eslint-disable-next-line vue/no-unused-components
    SwiperSlide,
  },
  setup() {
    const resolution = document.documentElement.clientWidth;

    return {
      resolution,
    };
  },
};
</script>

<style lang="sass" scoped>
.collaborations
  @apply bg-cover
  height: 347px
  background-color: #000000
  font-family: 'Montserrat'
  font-style: normal
  color: #FFFFFF
  .margin-block
    background-color: #000000
    height: 20px
  .collaborations-line-1
    @apply flex justify-center items-center content-center mt-12
    img
      @apply h-52
</style>
