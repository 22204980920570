<template>
  <span class="element">
    <img
        v-if="features.included"
        style="height: 13px; width: 19px"
        :src="require('@/assets/images/included.png')"
        alt="timeouttick"
    >
    <img
        v-if="features.notIncluded"
        style="height: 20px; width: 19px"
        :src="require('@/assets/images/notIncluded.png')"
        alt="timeouttick"
    >
    <img
        v-if="features.finally"
        style="height: 13px; width: 19px"
        :src="require('@/assets/images/check-blue.png')"
        alt="timeouttick"
    >
    <img
        v-if="features.amaproUpgrade"
        style="height: 13px; width:14px"
        :src="require('@/assets/images/amapro-upgrade-arrow.png')"
        alt="timeouttick"
    >
    <p>{{ element }}</p>
  </span>
</template>

<script>
import { defineComponent, reactive } from 'vue';

export default defineComponent({
  props: {
    element: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const features = reactive({
      included: false,
      notIncluded: false,
      finally: false,
      amaproUpgrade: false,
    });

    if (props.type === 'inlcuded') {
      features.included = true;
    }

    if (props.type === 'notInlcuded') {
      features.notIncluded = true;
    }

    if (props.type === 'finally') {
      features.finally = true;
    }

    if (props.type === 'amaproUpgrade') {
      features.amaproUpgrade = true;
    }

    return {
      features,
    };
  },
});
</script>

<style lang="sass">
.element
  @apply flex
  img
    @apply mr-2 mt-[5px]
  p
    @apply mt-[5px]
</style>
