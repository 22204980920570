/* eslint camelcase: ["error", {allow: [".*"]}] */

import { LocationQuery } from 'vue-router';

export default function getCouponCode({ utm_id }: LocationQuery): string|null {
  if (!utm_id) {
    return null;
  }

  return utm_id.toString();
}
