
import { defineComponent, onMounted, reactive } from 'vue';
import Modal from '@/components/atoms/Modal.vue';
import FeaturesCheckBox from '@/components/atoms/FeaturesCheckBox.vue';
import ListElement from '@/components/atoms/ListElement.vue';
import useAmplitude from '@/composables/useAmplitude';

export default defineComponent({
  components: {
    ListElement,
    FeaturesCheckBox,
    Modal,
  },
  emits: ['activateStep3', 'cancel', 'exitSteps'],
  props: {
    elementsIncluded: {
      type: [],
      required: true,
    },
    elementsNotIncluded: {
      type: [],
      required: true,
    },
    elementsFeatured: {
      type: [],
      required: true,
    },
  },
  setup() {
    const showFeatures = reactive({
      featuresIncluded: true,
      featuresNotIncluded: false,
    });

    onMounted(() => {
      useAmplitude()?.logEvent('Timeout_plan_screen');
      (window as any)?.dataLayer.push({
        event: 'Timeout_plan_screen',
      });
    });

    function showAllFeatures() {
      showFeatures.featuresNotIncluded = !showFeatures.featuresNotIncluded;
      useAmplitude()?.logEvent('Timeout_plan_info');
      (window as any)?.dataLayer.push({
        event: 'Timeout_plan_info',
      });
    }

    return {
      showFeatures,
      showAllFeatures,
    };
  },
});
