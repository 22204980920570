
import { computed, defineComponent } from 'vue';

export default defineComponent({
  props: {
    priceBeforeDiscount: {
      type: String,
      required: true,
    },
    priceAfterDiscount: {
      type: String,
      required: true,
    },
    couponApplied: {
      type: String,
      required: true,
    },
    couponColor: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const priceWithDiscount = computed(() => (props.priceAfterDiscount !== null));

    return {
      priceWithDiscount,
    };
  },
});
