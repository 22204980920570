
import { defineComponent, reactive, ref } from 'vue';
import Header from '@/components/molecules/Header.vue';
import store from '@/store';
import { useLoading } from 'vue-loading-overlay';
import ActivatePlan from '@/domain/models/ActivatePlan';
import Modal from '@/components/atoms/Modal.vue';
import Button from '@/components/atoms/button.vue';
import { getButtonVariant } from '@/domain/services/planHelper';
import CurrentSubscription from '@/domain/models/CurrentSubscription';
import LabelValue from '@/domain/models/LabelValue';
import UpgradePlansModal from '@/components/molecules/UpgradePlansModal.vue';
import PlanCard from '@/components/organisms/PlanCard.vue';
import FeatureCards from '@/components/organisms/FeaturesCards.vue';
import { PRECIOS_SUSCRIPCION, TEXTO_PACKS } from '@/constants/texts.es';
import Subscription from '@/domain/models/Subscription';

export default defineComponent({
  name: 'HomeView',
  components: {
    UpgradePlansModal,
    PlanCard,
    Header,
    Modal,
    Button,
    FeatureCards,
  },
  setup() {
    const planCards = ref(store.getters.plans);
    const variants = ['turquoise', 'purple'];
    const loading = useLoading({ backgroundColor: 'black', color: 'white' });
    const modals = reactive({
      confirm: false,
      updated: false,
      contact: false,
      forbiddenSubscription: false,
    });
    const planTypes = [
      new LabelValue('Mensual', '2'),
      new LabelValue('Semestral', '1'),
      new LabelValue('Anual', '0'),
    ];
    const modalMessage = ref();
    const modalVariant = ref();
    const modalVariantOutlined = ref();
    let { currentSubscription }: { currentSubscription: CurrentSubscription|null } = store.getters;
    if (currentSubscription === null) {
      const loader = loading.show();
      store.dispatch('currentSubscription')
        .then((res) => {
          currentSubscription = res;
          loader.hide();
        });
    }
    let defaultPaymentId = null as string|null;
    const elementsFeatured = ref();
    let planTypeSelected: Subscription|null = null;

    if (planCards.value.length < 1) {
      const loader = loading.show();
      store.dispatch('getPlans')
        .then((res) => {
          planCards.value.unshift(...res);
          loader.hide();
        });
    }

    const planSelected = (planCardId: Subscription) => {
      planTypeSelected = planCardId;

      modalVariant.value = 'purple';
      modalVariantOutlined.value = 'purple outlined';
      modals.confirm = true;
    };

    const contact = () => {
      modals.confirm = false;
      modals.contact = true;
    };

    function confirmUpgradeWithSecurity(res: any, price: any) {
      const loader = loading.show();
      const stripe = new (window as any).Stripe(process.env.VUE_APP_STRIPE_KEY);
      const clientSecret = res.subscription.latest_invoice.payment_intent.client_secret;
      stripe.confirmCardPayment(clientSecret).then(() => {
        store.commit('unsetCurrentSubscription');
        loader.hide();
        modalMessage.value = res.update_plan_confirmation_text;
        modalVariant.value = getButtonVariant(price?.name as string);
        modals.updated = true;
      });
    }

    function confirmUpgrade(res: any, price: any) {
      const loader = loading.show();
      store.commit('unsetCurrentSubscription');
      loader.hide();
      modalMessage.value = res.update_plan_confirmation_text;
      modalVariant.value = getButtonVariant(price?.name as string);
      modals.updated = true;
    }

    const update = async () => {
      const loader = loading.show();
      await store.dispatch('getDefaultPayment')
        .then((res) => {
          defaultPaymentId = res.data.credit_card_id;
        });
      if (defaultPaymentId) {
        const price = planTypeSelected;
        const data = new ActivatePlan(defaultPaymentId, price?.paymentProductId ? price?.paymentProductId : '', null);
        store.dispatch('activatePlan', data)
          .then((res) => {
            loader.hide();
            modals.confirm = false;
            if (res.data.data.subscription.latest_invoice) {
              confirmUpgradeWithSecurity(res.data.data, price);
            } else {
              confirmUpgrade(res.data.data, price);
            }
            elementsFeatured.value = price?.$elementsFeatured;
          })
          .catch((error) => {
            if (error.message === 'Request failed with status code 400'
                || error.message === 'Request failed with status code 409') {
              contact();
              loader.hide();
            }
          });
      }
    };

    function closeModal() {
      modals.updated = false;
    }

    return {
      planCards,
      variants,
      modals,
      modalVariant,
      modalVariantOutlined,
      modalMessage,
      currentSubscription,
      elementsFeatured,
      planTypeSelected,
      planTypes,
      PRECIOS_SUSCRIPCION,
      TEXTO_PACKS,

      planSelected,
      update,
      closeModal,
    };
  },
});
