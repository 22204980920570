export default class RegisterUserForm {
  $name: string;

  $email: string;

  $password: string;

  constructor({ name, email, password }: { name: string, email: string, password: string }) {
    this.$name = name;
    this.$email = email;
    this.$password = password;
  }

  get name(): string {
    return this.$name;
  }

  get email(): string {
    return this.$email;
  }

  get password(): string {
    return this.$password;
  }
}
