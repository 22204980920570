import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a4ef741"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "promo-code" }
const _hoisted_2 = { class: "input" }
const _hoisted_3 = {
  key: 0,
  class: "button"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  key: 1,
  class: "button"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RegularInput = _resolveComponent("RegularInput")!
  const _component_SecondaryButton = _resolveComponent("SecondaryButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_RegularInput, {
        modelValue: _ctx.modelValue?.toUpperCase(),
        placeholder: "Código promocional",
        class: _normalizeClass(["text-sm", { applied: _ctx.applied }]),
        disabled: _ctx.applied,
        error: _ctx.error ? 'El código promocional no es válido' : '',
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', _ctx.throwEvent($event.target))))
      }, null, 8, ["modelValue", "disabled", "class", "error"])
    ]),
    (_ctx.applied)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_SecondaryButton, null, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                style: {"margin-left":"2em","margin-top":"-0.5em"},
                src: require('@/assets/images/checked.svg'),
                alt: "aplicado"
              }, null, 8, _hoisted_4)
            ]),
            _: 1
          })
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_SecondaryButton, {
            disabled: _ctx.modelValue === '',
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('apply')))
          }, {
            default: _withCtx(() => [
              _createTextVNode("Aplicar")
            ]),
            _: 1
          }, 8, ["disabled"])
        ]))
  ]))
}