
import CurrentSubscription from '@/domain/models/CurrentSubscription';
import User from '@/domain/models/User';
import { computed, defineComponent, reactive } from 'vue';
import reenableSubscriptionHelper from '@/domain/services/reenableSubscriptionHelper';
import SubscriptionCard from '@/domain/models/SubscriptionCard';
import Modal from '@/components/atoms/Modal.vue';
import useAmplitude from '@/composables/useAmplitude';
import ProfilePrice from '@/components/atoms/ProfilePrice.vue';
import Button from '../atoms/button.vue';

export default defineComponent({
  components: {
    ProfilePrice,
    Modal,
    Button,
  },
  emits: ['reenableSubscription'],
  props: {
    currentSubscription: {
      type: CurrentSubscription,
      required: true,
    },
    user: {
      type: User,
      required: true,
    },
    subscriptionCard: {
      type: SubscriptionCard,
      required: true,
    },
  },
  setup(props) {
    const variants = ['turquoise', 'purple'];
    const modals = reactive({
      confirm: false,
    });
    const isAmateurCurrent = (computed(() => props.currentSubscription.lastPayType === 'AMATEUR'));
    const isAmateurNext = (computed(() => props.currentSubscription.nextPayType === 'AMATEUR'));
    const isMaintenanceCurrent = computed(() => props.currentSubscription.lastPayType === 'MANTENIMIENTO');
    const isMaintenanceNext = computed(() => props.currentSubscription.nextPayType === 'MANTENIMIENTO');
    const cardImageTypes = reactive({
      maintenance: 'white-card.svg',
      amateur: 'turquoise-card.svg',
      amapro: 'purple_card.svg',
    });
    const cardImageCurrent = (computed(() => {
      if (isAmateurCurrent.value) {
        return cardImageTypes.amateur;
      }

      if (isMaintenanceCurrent.value) {
        return cardImageTypes.maintenance;
      }

      return cardImageTypes.amapro;
    }));
    const cardImageNext = (computed(() => {
      if (isAmateurNext.value) {
        return cardImageTypes.amateur;
      }

      if (isMaintenanceNext.value) {
        return cardImageTypes.maintenance;
      }

      return cardImageTypes.amapro;
    }));
    const discountCurrecntColor = (computed(() => {
      if (isAmateurCurrent.value) {
        return 'turquoise';
      }

      if (isMaintenanceCurrent.value) {
        return 'white';
      }

      return 'purple';
    }));
    const discountNextColor = (computed(() => {
      if (isAmateurCurrent.value) {
        return 'turquoise';
      }

      if (isMaintenanceCurrent.value) {
        return 'white';
      }

      return 'purple';
    }));

    function isCancelledButton() {
      if (props?.subscriptionCard?.cardNumber === undefined) {
        return 'NO-CARD-PAYMENT';
      }
      return reenableSubscriptionHelper(props.user);
    }

    function isCancelledStatus() {
      return reenableSubscriptionHelper(props.user);
    }

    function logResubscription() {
      useAmplitude()?.logEvent('Profile_reactivate_selected');
    }

    function addPaymentModal() {
      modals.confirm = true;
    }

    return {
      modals,
      variants,
      isAmateurCurrent,
      isAmateurNext,
      isMaintenanceCurrent,
      isMaintenanceNext,
      cardImageCurrent,
      cardImageNext,
      discountCurrecntColor,
      discountNextColor,

      logResubscription,
      isCancelledButton,
      addPaymentModal,
      isCancelledStatus,
    };
  },
});
