
import { defineComponent, onMounted, ref } from 'vue';
import PlanCard from '@/components/organisms/PlanCard.vue';
import PlanCardModel from '@/domain/models/PlanCard';
import Header from '@/components/molecules/Header.vue';
import { useRoute, useRouter } from 'vue-router';
import store from '@/store';
import { useLoading } from 'vue-loading-overlay';
import NavigationRedirector from '@/domain/services/NavigationRedirector';
import Ambassadors from '@/components/organisms/Ambassadors.vue';
import UniqueAsYou from '@/components/organisms/UniqueAsYou.vue';
import Nutritionists from '@/components/organisms/Nutritionists.vue';
import IndyaInFacts from '@/components/organisms/IndyaInFacts.vue';
import GoogleReviews from '@/components/organisms/GoogleReviews.vue';
import Awakers from '@/components/organisms/Awakers.vue';
import Collaborations from '@/components/organisms/Collaborations.vue';
import RedirectCta from '@/components/organisms/RedirectCta.vue';
import UniqueAsYouMobile from '@/components/organisms/UniqueAsYouMobile.vue';
import LabelValue from '@/domain/models/LabelValue';
import CollaborationsMobile from '@/components/organisms/CollaborationsMobile.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import useAmplitude from '@//composables/useAmplitude';
import { PRECIOS_SUSCRIPCION, TEXTO_PACKS } from '@/constants/texts.es';
import FeatureCards from '@/components/organisms/FeaturesCards.vue';

// eslint-disable-next-line import/extensions
import 'swiper/scss';
// eslint-disable-next-line import/extensions
import 'swiper/scss/navigation';
// eslint-disable-next-line import/extensions
import 'swiper/scss/pagination';
import Pro from '@/plans/Pro';
import AwakersMobile from '@/components/organisms/AwakersMobile.vue';
import SwiperCore, { Mousewheel, Pagination } from 'swiper';

export default defineComponent({
  name: 'HomeView',
  components: {
    AwakersMobile,
    CollaborationsMobile,
    UniqueAsYouMobile,
    PlanCard,
    Header,
    Ambassadors,
    UniqueAsYou,
    Nutritionists,
    IndyaInFacts,
    GoogleReviews,
    Awakers,
    Collaborations,
    RedirectCta,
    FeatureCards,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    // const planCards: any = ref([]);
    const planCards = ref([...store.getters.planTimeOut]);
    const variants = ['turquoise', 'purple', 'magenta'];
    const planTypes = [
      new LabelValue('Mensual', '2'),
      new LabelValue('Semestral', '1'),
      new LabelValue('Anual', '0'),
    ];
    const planTypeSelected = ref('2');
    onMounted(() => {
      useAmplitude()
        ?.logEvent('Onboarding_prices_screen');
    });
    const coupon = store.getters.coupon !== null;

    if (planCards.value.length < 3) {
      planCards.value.push(Pro);
    }

    if (store.getters.loggedUser) {
      if (NavigationRedirector.userHasPayedSubscription(store.getters.user)) {
        router.push({ name: 'profile' });
      }
    }

    const resolution = document.documentElement.clientWidth;
    if (planCards.value.length < 2) {
      // if (coupon) {
      const loading = useLoading({
        backgroundColor: 'black',
        color: 'white',
      });
      const loader = loading.show();
      store.dispatch('getPlans')
        .then((res) => {
          planCards.value.unshift(...res);
          loader.hide();
        });
    }

    const planSelected = (planCardId: string, subscriptionId: string) => {
      if (planCardId === '') {
        window.open(process.env.VUE_APP_SPECIAL_PLAN_LINK, '_blank');
        return;
      }

      router.push({
        name: 'register',
        query: {
          ...route.query,
          data: 'email',
          planId: planCardId,
          subscriptionId,
        },
      });
    };

    function freeMonthsValue(selectedPlan: string) {
      const value = planCards.value[0].subscriptions[parseInt(selectedPlan, 10)].promo;
      if (value === null || value === undefined) {
        return '';
      }
      return value;
    }

    function monthlyPaymentCondition(selectedPlan: string) {
      const value = planCards.value[0].subscriptions[parseInt(selectedPlan, 10)].paymentConditions;
      if (value === null || value === undefined) {
        return '';
      }
      return value;
    }

    SwiperCore.use([Mousewheel, Pagination]);

    return {
      planCards,
      variants,
      resolution,
      coupon,
      PRECIOS_SUSCRIPCION,
      TEXTO_PACKS,

      planSelected,
      planTypes,
      planTypeSelected,
      freeMonthsValue,
      monthlyPaymentCondition,
    };
  },
});
