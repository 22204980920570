
import {
  computed, defineComponent, reactive, ref,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { HEADER_TITLE_1, HEADER_TITLE_2 } from '@/constants/texts.es';
import Login from '../atoms/Login.vue';

export default defineComponent({
  components: { Login },

  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const showHeader = computed(() => (router.currentRoute.value.path !== '/profile' && router.currentRoute.value.name !== 'change-plan'));
    const opened = ref(false);
    const { user } = store.getters;
    const headerType = route.query.utm_sport ? route.query.utm_sport : '';
    const headerTexts = {
      default: {
        title: 'Suscríbete a tu nutricionista deportivo',
      },
      running: {
        title: 'Suscríbete a tu nutricionista especialista en ciclismo\n'
            + 'y revoluciona tu FPT',
      },
      ciclismo: {
        title: 'Suscríbete a tu nutricionista especialista en ciclismo\n'
            + 'y revoluciona tu FPT',
      },
      crossfit: {
        title: 'Suscríbete a tu nutricionista especialista en Crossfit\n'
            + 'y borda tus WODs',
      },
      gimnasio: {
        title: 'Suscríbete a tu nutricionista especialista en fitness\n'
            + 'y mejora tus entrenamientos',
      },
    };

    function getTitle() {
      if (headerType === 'running') {
        return headerTexts.running.title;
      }
      if (headerType === 'ciclismo') {
        return headerTexts.ciclismo.title;
      }
      if (headerType === 'crossfit') {
        return headerTexts.crossfit.title;
      }
      if (headerType === 'gimnasio') {
        return headerTexts.gimnasio.title;
      }

      return headerTexts.default.title;
    }

    const title = getTitle();

    const logout = () => {
      store.commit('logout');
      router.push({ name: 'login' });
    };

    const isPromo = () => {
      if (route.query.utm_campaign) {
        return true;
      }
      return false;
    };

    const short = (text: string) => {
      if (text.length < 8) {
        return text;
      }
      const ret = text.substring(0, 5);

      return `${ret}...`;
    };

    return {
      user,
      opened,
      title,
      showHeader,
      HEADER_TITLE_1,
      HEADER_TITLE_2,

      isPromo,
      logout,
      short,
    };
  },
});
