export default class PlanCardElement {
  $paymentTitle: string;

  $elementText: string;

  constructor($paymentTitle: string, $elementText: string) {
    this.$paymentTitle = $paymentTitle;
    this.$elementText = $elementText;
  }

  get paymentTitle(): string {
    return this.$paymentTitle;
  }

  get elementText(): string {
    return this.$elementText;
  }
}
