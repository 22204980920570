
import { computed, defineComponent, reactive } from 'vue';
import Button from '@/components/atoms/button.vue';
import {
  validateEmpty,
  validatePassword,
  validateEmail,
  validateNameField,
  validatePasswordField,
  validateEmailField,
} from '@/domain/services/validationHelper';
import RegisterUserForm from '@/domain/models/RegisterUserForm';
import NameInput from '../molecules/NameInput.vue';
import EmailInput from '../molecules/EmailInput.vue';
import PasswordInput from '../molecules/PasswordInput.vue';

export default defineComponent({
  components: {
    Button,
    NameInput,
    EmailInput,
    PasswordInput,
  },
  emits: ['click'],
  setup(_, { emit }) {
    const form = reactive({
      name: '',
      email: '',
      password: '',
    });
    const errors = reactive({
      name: '',
      email: '',
      password: '',
    });
    (window as any).dataLayer.push({
      event: 'virtual_pageview_email',
    });

    const formValidated = computed(() => {
      if (validateEmpty(form.name) || validateEmpty(form.password) || validateEmpty(form.email)) {
        return false;
      }

      if (!validateEmail(form.email)) {
        return false;
      }

      if (!validatePassword(form.password)) {
        return false;
      }
      return true;
    });

    const throwEvent = () => {
      emit('click', new RegisterUserForm(form));
    };

    return {
      form,
      formValidated,
      errors,

      validateNameField,
      validateEmailField,
      validatePasswordField,
      throwEvent,
    };
  },
});
