<template>
  <div class="time-out-accept-step3">
    <div class="accept-content">
      <h2>Plan Mantenimiento</h2>
      <hr>
      <ul style="text-align: center">
        <li class="elements-step3" v-for="element in elementsIncluded" :key="element">
          <ListElement :element="element" type="inlcuded"/>
        </li>
        <li class="elements-step3" v-for="element in elementsNotIncluded" :key="element">
          <ListElement :element="element" type="notInlcuded"/>
        </li>
      </ul>
      <p class="info-timeout">El servicio Timeout incluye todas las funcionalidades que se muestran
        en esta pantalla como habilitadas con un check en turquesa.
        No incluye las que se muestran junto al icono del candado.</p>
      <Button variant="turquoise"
              @click="$emit('desactivateStep3')">Entendido
      </Button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import ListElement from '@/components/atoms/ListElement.vue';
import Button from '../atoms/button.vue';

export default defineComponent({
  components: { ListElement, Button },
  emits: ['desactivateStep3'],
  props: {
    elementsIncluded: {
      type: [],
      required: true,
    },
    elementsNotIncluded: {
      type: [],
      required: true,
    },
  },
});
</script>

<style lang="sass">
.time-out-accept-step3
  background-color: #212330
  @apply flex flex-col items-stretch w-11/12 text-white h-auto
  .accept-content
    @apply h-auto
    h2
      @apply h-auto text-center text-2xl font-bold py-7 px-16 mx-4
    hr
      @apply mx-6
    ul
      @apply h-auto flex flex-col mb-auto
      li
        @apply p-5
        img
          @apply mr-2
    p.info-timeout
      @apply mt-auto text-xs p-2 text-center
      color: #828282
    Button
      @apply px-2.5 mt-6 mb-6
</style>
