
import { defineComponent } from 'vue';
import SecondaryButton from '../atoms/SecondaryButton.vue';
import RegularInput from './RegularInput.vue';

export default defineComponent({
  name: 'PromoCode',
  components: { RegularInput, SecondaryButton },
  emits: ['update:modelValue', 'apply'],
  props: {
    modelValue: String,
    applied: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const throwEvent = (target: EventTarget|null) => (target as HTMLInputElement).value;

    return {
      throwEvent,
    };
  },
});
