import { RawUserSubscription } from '../services/types';

/* eslint camelcase: ["error", {allow: [".*"]}] */
export default class UserSubscription {
  $createdAt: string;

  $updatedAt: string;

  $validFrom: string;

  $validTo: string;

  $platform: string;

  constructor({
    created_at, updated_at, valid_from, valid_to, platform,
  }: RawUserSubscription) {
    this.$createdAt = created_at;
    this.$updatedAt = updated_at;
    this.$validFrom = valid_from;
    this.$validTo = valid_to;
    this.$platform = platform;
  }

  get createdAt(): string {
    return this.$createdAt;
  }

  get updatedAt(): string {
    return this.$updatedAt;
  }

  get validFrom(): string {
    return this.$validFrom;
  }

  get validTo(): string {
    return this.$validTo;
  }

  get platform(): string {
    return this.$platform;
  }
}
