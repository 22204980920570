
import {
  defineComponent,
} from 'vue';

export default defineComponent({
  emits: ['message', 'update:modelValue'],
  props: {
    showPlaceholder: Boolean,
    isActive: Boolean,
    placeholder: String,
    error: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const throwEvent = (event: Event) => {
      const text = (event.target as HTMLInputElement).value;
      emit('update:modelValue', text);
    };

    return {
      throwEvent,
    };
  },
});
