export default class BasicAuth {
  private $email: string;

  private $token: string;

  constructor(email: string, token: string) {
    this.$email = email;
    this.$token = token;
  }

  get email(): string {
    return this.$email;
  }

  get token(): string {
    return this.$token;
  }

  get mangledData(): string {
    return btoa(`${this.email}:${this.token}`);
  }
}
