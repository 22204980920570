import PlanCardFactory from '@/domain/services/PlanCardFactory';
import axios from '@/plugins/axios';
import store from '@/store';

const PLAN_URL_NEW = '/v3/fares';
const PLAN_URL_TIMEOUT = '/v3/onboarding/fares?is_cancelation=true';
const PLAN_URL_BY_PRODUCT_ID = '/v3/fares/fare-by-product-id';

function getURL() {
  if (store.getters.coupon) {
    return `${`${PLAN_URL_NEW}?cupon=${store.getters.coupon}`}`;
  }
  return PLAN_URL_NEW;
}

function getProductURL(productId: string) {
  if (store.getters.coupon) {
    return `${`${PLAN_URL_BY_PRODUCT_ID}?cupon=${store.getters.coupon}&product_id=${productId}`}`;
  }
  return `${`${PLAN_URL_BY_PRODUCT_ID}?product_id=${productId}`}`;
}

export async function getPlans() {
  if (store.getters.token) {
    axios.defaults.headers.common.Authorization = `Bearer ${store.getters.token}`;
  }
  return axios.get(getURL())
    .then((res) => PlanCardFactory.getAmaproPlan(res.data.data))
    .catch((error) => Promise.reject(error));
}

export async function getPlanTimeOut() {
  if (store.getters.token) {
    axios.defaults.headers.common.Authorization = `Bearer ${store.getters.token}`;
  }
  return axios.get(PLAN_URL_TIMEOUT)
    .then((res) => PlanCardFactory.getPlans(res.data.data))
    .catch((error) => Promise.reject(error));
}

export async function getPlanByProductId(productId: string) {
  if (store.getters.token) {
    axios.defaults.headers.common.Authorization = `Bearer ${store.getters.token}`;
  }
  return axios.get(getProductURL(productId))
    .then((res) => PlanCardFactory.getPlans(res.data.data))
    .catch((error) => Promise.reject(error));
}
