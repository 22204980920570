
import { defineComponent } from 'vue';

export default defineComponent({
  setup() {
    const GoogleReviews = [
      {
        name: 'Dan Manasés',
        description: 'Me marqué un objetivo que llevaba años intentando y lo conseguí en pocos meses. Le ponen pasión a lo'
            + 'que hacen y eso se nota. Profesionalidad y empatía con una app que te lo pone facilísimo. 100% recomendable.',
        puesto: 'Director Creativo',
        imagen: 'Carlos-Bonafé-March-2.png',
      },
      {
        name: 'Víctor Beltrán',
        description: 'Ya hace un año y poco que empecé con ellos y mi rendimiento ha ido aumentando considerablemente. '
            + 'Se ajustan a lo que necesitas y es muy fácil seguir sus planificaciones. Son Top!',
        puesto: 'Fisioterapeuta',
        imagen: 'Carlos-Bonafé-March-1.png',
      },
      {
        name: 'Silvia Salcedo',
        description: 'Llevo cuatro meses con ellos y estoy súper contenta. Nunca había disfrutado tanto comiendo de manera '
            + 'saludable. Se adaptan a todas tus preferencias. Te pones un reto y ellos te ayudan en todo. Muy profesionales.',
        puesto: 'Ejecutiva de Cuentas',
        imagen: 'Carlos-Bonafé-March.png',
      },
    ];
    const randomReviewNumber = Math.floor(Math.random() * GoogleReviews.length);
    return {
      GoogleReviews,
      randomReviewNumber,
    };
  },
});

