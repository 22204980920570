
import { defineComponent, ref } from 'vue';
import PromoCode from '../molecules/PromoCode.vue';

export default defineComponent({
  components: { PromoCode },
  props: {
    modelValue: String,
    appliedDiscount: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'apply'],
  setup() {
    const opened = ref(false);
    const throwEvent = (target: EventTarget|null) => (target as HTMLInputElement).value;

    return {
      opened,

      throwEvent,
    };
  },
});
