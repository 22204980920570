<template>
    <iframe width="100%" height="223px"
            src="https://www.youtube.com/embed/J5NiVcE7pNk?autoplay=0&mute=1&loop=1&authide=1&disablekb=1&rel=0&playlist=J5NiVcE7pNk"
            title="YouTube video player"
            frameborder="0"
            allow=
                "accelerometer;
                autoplay;
                clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
</template>

<style lang="sass" scoped>
img
  @apply bg-cover
</style>
