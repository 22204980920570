import { createStore, Module } from 'vuex-smart-module';
import actions from '@/store/actions';
import getters from '@/store/getters';
import mutations from '@/store/mutations';
import { State } from '@/store/state';

export default createStore(
  new Module({
    state: State,
    getters,
    mutations,
    actions,
  }),
);
