import Discount from '@/domain/models/Discount';
import PromoCode from '@/domain/models/PromoCode';
import { RawPromoCode } from '@/domain/services/types';
import axios from '@/plugins/axios';

const CHECK_URL = '/v2/stripe-payment/check-promotional-code?promo_code={code}&stripe_price_id={price_id}';

export async function check(promoCode: PromoCode) {
  const url = CHECK_URL
    .replace('{code}', promoCode.code)
    .replace('{price_id}', promoCode.priceId)
    .replace('{promotionCodeName}', promoCode.promotionCodeName);
  return axios.get(url)
    .then((res) => {
      const { finalPrice, promotionCodeId, promotionCodeName }: RawPromoCode = res.data.data;
      return new Discount(promoCode.code, finalPrice, promotionCodeId, promotionCodeName);
    })
    .catch((error) => Promise.reject(error));
}

export async function dummy() {
  console.log();
}
