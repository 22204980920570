/* eslint camelcase: ["error", {allow: [".*"]}] */
import CancelMotive from '@/domain/models/CancelMotive';
import CurrentSubscription from '@/domain/models/CurrentSubscription';
import SubscriptionCard from '@/domain/models/SubscriptionCard';
import User from '@/domain/models/User';
import UserSubscription from '@/domain/models/UserSubscription';
import { RawBasicInfo, RawCancelMotive } from '@/domain/services/types';
import axios from '@/plugins/axios';

const URL_USER = 'v3/user/basic-info';
const URL_SUBSCRIPTION_DATA = '/v2/athlete/subscription-data';
const URL_GET_SUBSCRIPTION = '/user/get-subscription';
const URL_REACTIVATE_SUBSCRIPTION = '/v2/subscription/reactivate-subscription';
// cambiar por nuevo endpoint v3/profile
const URL_CURRENT_SUBSCRIPTION = '/v3/profile';
const URL_CANCEL_MOTIVES = '/v2/subscription/get-cancellation-reasons';
const URL_RESET_EMAIL = '/reset-password/recovery-mail';

export async function getSubscription() {
  return axios
    .get(URL_GET_SUBSCRIPTION)
    .then((res) => new UserSubscription(res.data.data.subscription))
    .catch((error) => Promise.reject(error));
}

export async function reactivateSubscription() {
  return axios.post(URL_REACTIVATE_SUBSCRIPTION);
}

export async function userBasicInfo() {
  return axios
    .get(URL_USER)
    .then((res) => {
      const {
        invitation_name,
        username,
        client_id,
        plan,
        activeSubscriptionName,
        subscriptionStatus,
        navigation,
        userImage,
      }: RawBasicInfo = res.data.data;
      return new User(
        invitation_name,
        username,
        navigation,
        client_id,
        userImage,
        plan,
        activeSubscriptionName,
        subscriptionStatus,
      );
    })
    .catch((error) => Promise.reject(error));
}

export async function subscriptionData() {
  return axios
    .get(URL_SUBSCRIPTION_DATA)
    .then((res) => new SubscriptionCard(res.data.data.components.credit_card))
    .catch((error) => Promise.reject(error));
}

export async function currentSubscription() {
  // adaptar estructura de CurrentSuscription
  return axios
    .get(URL_CURRENT_SUBSCRIPTION)
    .then((res) => new CurrentSubscription(
      res.data.data.payment_data,
      res.data.data.manual_update_plan_popup_text,
      res.data.data.is_pack,
    ))
    .catch((error) => Promise.reject(error));
}

export async function cancelMotives() {
  return axios
    .get(URL_CANCEL_MOTIVES)
    .then((res) => res
      .data
      .data
      .map((motive: RawCancelMotive) => {
        const id = motive.subscription_cancellation_reason_id;
        return new CancelMotive(id, motive.reason);
      }))
    .catch((error) => Promise.reject(error));
}

export async function resetEmail(email: string) {
  return axios
    .post(URL_RESET_EMAIL, { email })
    .then((res) => res)
    .catch((error) => Promise.reject(error));
}
