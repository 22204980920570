
import { defineComponent, PropType } from 'vue';
import { Steps } from '@/domain/services/types';
import StepsTag from '../atoms/Steps.vue';

export default defineComponent({
  props: {
    elements: Array as PropType<Array<string>>,
    selectedIndex: {
      type: Number,
      default: 0,
    },
    step: {
      type: Number as PropType<Steps>,
    },
  },
  components: { StepsTag },
  setup() {
    return { Steps };
  },
});
