import { Actions } from 'vuex-smart-module';
import { login, register } from '@/api/AuthAPI';
import { getPlans, getPlanTimeOut, getPlanByProductId } from '@/api/PlanAPI';
import {
  createCard,
  activatePlan,
  updatePlan,
  getDefaultPaymentId,
  cancelSubscription,
  retryPayment,
} from '@/api/PaymentAPI';
import {
  reactivateSubscription,
  getSubscription,
  userBasicInfo,
  subscriptionData,
  currentSubscription,
  cancelMotives,
  resetEmail,
} from '@/api/UserAPI';
import {
  check as checkPromoCode,
} from '@/api/PromoCode';
import LoginUserForm from '@/domain/models/LoginUserForm';
import { State } from '@/store/state';
import getters from '@/store/getters';
import mutations from '@/store/mutations';
import PlanCard from '@/domain/models/PlanCard';
import RegisterUserForm from '@/domain/models/RegisterUserForm';
import PromoCode from '@/domain/models/PromoCode';
import User from '@/domain/models/User';
import BasicAuth from '@/domain/models/BasicAuth';
import ActivatePlan from '@/domain/models/ActivatePlan';
import UserSubscription from '@/domain/models/UserSubscription';
import { fetchMonthlyReport as fetchMonthlyReportRequest } from '@/api/ReportAPI';
import { MonthlyReportData, DEFAULT_NAVIGATION } from '@/domain/services/types';

export default class extends Actions<State, getters, mutations> {
  async login(formData: LoginUserForm) {
    this.commit('logout');
    return login(formData)
      .then((res: BasicAuth) => {
        this.commit('setAuth', res.mangledData);
        this.commit('setUser', new User('', '', DEFAULT_NAVIGATION, -1, ''));
        return res;
      })
      .catch((error) => Promise.reject(error));
  }

  async register(formData: RegisterUserForm) {
    return register(formData)
      .then(() => null)
      .catch((error) => Promise.reject(error));
  }

  async reactivateSubscription() {
    return reactivateSubscription()
      .then(() => null)
      .catch((error) => Promise.reject(error));
  }

  async getSubscription() {
    return getSubscription()
      .then((res: UserSubscription) => res)
      .catch((error) => Promise.reject(error));
  }

  async userBasicInfo() {
    return userBasicInfo()
      .then((res: User) => {
        this.commit('setUser', res);
        return res;
      })
      .catch((error) => Promise.reject(error));
  }

  async subscriptionData() {
    return subscriptionData()
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  }

  async currentSubscription() {
    return currentSubscription()
      .then((res) => {
        this.commit('setCurrentSubscription', res);
        return res;
      })
      .catch((error) => Promise.reject(error));
  }

  async cancelMotives() {
    return cancelMotives()
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  }

  async resetEmail(email: string) {
    return resetEmail(email)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  }

  async getPlans() {
    return getPlans()
      .then((res: PlanCard[]) => {
        this.commit('setPlans', res);
        return res;
      })
      .catch((error) => Promise.reject(error));
  }

  async getPlanTimeOut() {
    return getPlanTimeOut()
      .then((res: PlanCard[]) => {
        this.commit('setTimeOutPlan', res);
        return res;
      })
      .catch((error) => Promise.reject(error));
  }

  async getPlanByProductId(productId: string) {
    return getPlanByProductId(productId)
      .then((res: PlanCard[]) => {
        this.state.plans[0].subscriptions.push(res[0].subscriptions[0]);
        this.commit('setPlans', this.state.plans);

        return res[0].subscriptions[0];
      })
      .catch((error) => Promise.reject(error));
  }

  async checkPromoCode(data: PromoCode) {
    return checkPromoCode(data)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  }

  async createCard(token: string) {
    return createCard(token)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  }

  async activatePlan(data: ActivatePlan) {
    return activatePlan(data)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  }

  async updatePlan(productId: string) {
    return updatePlan(productId)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  }

  async cancelSubscription(motives: string) {
    return cancelSubscription(motives)
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  }

  async getDefaultPayment() {
    return getDefaultPaymentId()
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  }

  async retryPayment() {
    return retryPayment()
      .then((res) => res)
      .catch((error) => Promise.reject(error));
  }

  async fetchMonthlyReport(uuid: string): Promise<MonthlyReportData> {
    return fetchMonthlyReportRequest(uuid).then((res) => {
      this.commit('setMonthlyReport', res);
      return res;
    });
  }
}
