<template>
  <div class="collaborations">
    <div class="margin-block"></div>
    <h3>COLABORACIONES</h3>
    <div class="collaborations-logos">
      <div class="collaborations-line-1">
        <img
           :src="require('@/assets/images/TEO-MARTIN.svg')"
            alt="titulo_de_seccion">
        <img
           :src="require('@/assets/images/QH.svg')"
            alt="titulo_de_seccion">
        <img
           :src="require('@/assets/images/VALENCIA-CF.svg')"
            alt="titulo_de_seccion">
        <img
           :src="require('@/assets/images/GLAMOUR-SPORT.svg')"
            alt="titulo_de_seccion">
        <img
           :src="require('@/assets/images/TRICV.svg')"
            alt="titulo_de_seccion">
      </div>
      <div class="collaborations-line-2">
        <img
           :src="require('@/assets/images/RFE-CICLISMO.svg')"
            alt="titulo_de_seccion">
        <img
           :src="require('@/assets/images/JAGUAR.svg')"
            alt="titulo_de_seccion">
        <img
           :src="require('@/assets/images/REEBOK.svg')"
            alt="titulo_de_seccion">
        <img
           :src="require('@/assets/images/WATTSLAB.svg')"
            alt="titulo_de_seccion">
        <img
           :src="require('@/assets/images/REDBULL.svg')"
            alt="titulo_de_seccion">
      </div>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.collaborations
  @apply bg-cover
  height: 347px
  background-color: #000000
  font-family: 'Montserrat'
  font-style: normal
  color: #FFFFFF
  .margin-block
    background-color: #000000
    height: 20px
  .collaborations-logos
    display: flex
    flex-direction: column
    margin-top: 3em
    .collaborations-line-1
      display: flex
      flex-wrap: wrap
      justify-content: space-evenly
      img
        @apply sm:w-36 lg:w-48
    .collaborations-line-2
      display: flex
      flex-wrap: wrap
      justify-content: space-evenly
      margin-top: 2em
    img
      @apply sm:w-36 lg:w-48
</style>
