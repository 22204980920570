
import { defineComponent } from 'vue';
import PlanCardModel from '@/domain/models/PlanCard';
import Subscription from '@/domain/models/Subscription';
import Discount from '@/domain/models/Discount';
import { CardColor } from '@/domain/services/types';

export default defineComponent({
  props: {
    plan: {
      type: PlanCardModel,
      required: true,
    },
    subscription: {
      type: Subscription,
      required: true,
    },
    discount: {
      type: Discount,
      default: null,
    },
    discountName: {
      type: String,
      default: null,
    },
    selectedPlan: {
      type: Subscription,
      default: null,
    },
    originalPriceMonth: {
      type: Number,
      default: null,
    },
    priceMonth: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const showDiscount = () => {
      if (props.discountName && (props.subscription.basePrice !== props.subscription.price)) {
        return true;
      }
      return false;
    };

    return {
      planPrice: props.subscription.price,
      totalPlanPrice: props.subscription.price,
      CardColor,

      showDiscount,
    };
  },
});
