// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import amplitude from 'amplitude-js';

class AmplitudeWrapper {
  private static instance: AmplitudeWrapper|null = null;

  private client: amplitude.AmplitudeClient|null = null;

  constructor(key: string, enabled: boolean) {
    if (enabled === true) {
      this.client = amplitude.getInstance('Desktop');
      this.client.init(key, undefined, { includeUtm: true, domain: '.getindya.com' });
    }
  }

  public static getInstance(key: string, enabled: boolean): AmplitudeWrapper {
    if (AmplitudeWrapper.instance === null) {
      AmplitudeWrapper.instance = new AmplitudeWrapper(key, enabled);
    }
    return AmplitudeWrapper.instance;
  }

  public logEvent(event: string): void {
    if (this.client !== null) {
      this.client.logEvent(event);
    }
  }

  public logEventData(event: string, data: any): void {
    if (this.client !== null) {
      this.client.logEvent(event, data);
    }
  }

  public setUserId(userId: string): void {
    this.client?.setUserId(userId);
  }

  public setUserProperties(userProperties: object): void {
    this.client?.setUserProperties(userProperties);
  }
}
export default (): AmplitudeWrapper => {
  const amplitudeKey: string = process.env.VUE_APP_AMPLITUDE_KEY;
  const amplitudeEnabled: boolean = process.env.VUE_APP_AMPLITUDE_ENABLED === 'true';

  return AmplitudeWrapper.getInstance(amplitudeKey, amplitudeEnabled);
};
